import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { 
  getFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDZgJbJajbMkWe5925xitt0rVE6dTpMqrI",
  authDomain: "g2hinternal.firebaseapp.com",
  projectId: "g2hinternal",
  storageBucket: "g2hinternal.appspot.com",
  messagingSenderId: "972743267805",
  appId: "1:972743267805:web:8235fe0ecda20ccf879234",
};

// Initialize Firebase
//const initapp = initializeApp(firebaseConfig)

const initapp = initializeApp(firebaseConfig, { 
  localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()}), cacheSizeBytes: CACHE_SIZE_UNLIMITED
});


export const auth = getAuth(initapp);
export const db = getFirestore(initapp);
export const storage = getStorage(initapp);
