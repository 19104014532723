import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { pages, settings, permission } from "../listsnobjects";
import { loginStatus } from "../../firebase/LoginProvider";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { signout } from "../../firebase/authhandling";

export default function Navbar() {
  const { currentstatus, setCurrentstatus } = useContext(loginStatus);
  //console.log(currentstatus);
  let navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    signout();
  };

  const handleNavMenu = (prop) => () => {
    setAnchorElNav(null);
    //navigate(`/${prop.replace(/ /g, "")}`);
    navigate(`/${prop}`);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#819475" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {
            //Display in 900px or above
          }
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" }, color: "white" }}
          >
            Go 2 Health Group Ltd.
          </Typography>
          {
            //Display in MOBILE - Burger menu
          }
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="Current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#ffffff" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {
                //Only display the menu to those who have the access right
                pages.filter(page=>permission[page.id][currentstatus.type])
                .map((page) => (
                <MenuItem key={page.id} onClick={handleNavMenu(page.id)}>
                  <Typography textAlign="center">
                  { currentstatus.lang==="en" ? page.display : page.cdisplay }
                  </Typography>
                </MenuItem>
              ))
              }
            </Menu>
          </Box>
          {
            //Display in MOBILE - Company name
          }
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              color: "white",
              display: { xs: "flex", md: "none" },
            }}
          >
            Go 2 Health Group Ltd.
          </Typography>
          {
            //Display in 900px or above - Menu item
          }
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {
              //Only display the menu to those who have the access right
              pages.filter(page=>permission[page.id][currentstatus.type])
            .map((page) => (
              <NavLink
                key={page.id}
                style={({ isActive }) => {
                  return {
                    display: "block",
                    my: 1,
                    color: "#ffffff",
                    textDecoration: isActive ? "underline" : "inherit",
                  };
                }}
                //to={`/${page.replace(/ /g, "")}`}
                to={`/${page.id}`}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    color: "inherit",
                    textDecoration: "inherit",
                    fontWeight: "normal",
                    "&:hover": {
                      fontWeight: "bold",
                      textDecoration: "inherit",
                    },
                  }}
                >
                  { currentstatus.lang==="en" ? page.display : page.cdisplay }
                </Button>
              </NavLink>
            ))}
          </Box>
          {
            //User
          }
          <Box sx={{ flexGrow: 0 }}>
            <Button
              onClick={()=>setCurrentstatus({
                ...currentstatus,
                lang: currentstatus.lang==='zh' ? 'en' : 'zh',
              })}
              sx={{
                color: "inherit",
                textDecoration: "inherit",
                fontWeight: "normal",
                maxWidth: "80px",
                mr: 2,
                "&:hover": {
                  fontWeight: "bold",
                  textDecoration: "inherit",
                },
              }}
            >
              {currentstatus.lang==='zh' ? 'Eng' : '中文'}
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={currentstatus.username}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ backgroundColor: "#E59717" }}>
                  {currentstatus.username === "W/H"
                    ? "WH"
                    : currentstatus.username.slice(0, 1) +
                      currentstatus.username.split(" ")[1].slice(0, 1)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.id} onClick={handleLogout}>
                  <Typography textAlign="center">
                    { currentstatus.lang==="en" ? setting.display : setting.cdisplay }
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
