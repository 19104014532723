import React, { useState, useEffect }  from 'react'
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Box,
  Typography,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Autocomplete from '@mui/material/Autocomplete';
import { db } from "../../firebase/initfirebase";
import { datafetching, itemlistsorting } from "../../firebase/managedb";
import {
  collection,
  query,
  doc,
  setDoc,
  where,
} from "firebase/firestore";
import { itemobj, permission } from "../listsnobjects";

const useStyles = makeStyles({
  container: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "90%",
    },
    "@media (min-width:900px)": {
      width: "80%",
    },
    "@media (min-width:1200px)": {
      width: "60%",
    },
  },
  paperstyle: {
    padding: "16px",
    "@media (min-width:600px)": {
      padding: "32px",
    },
  },
  sfield: {
    width: "200px",
  },
  mfield: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "300px",
    },
  },
  lfield: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "500px",
    },
  },
  fullfield: {
    width: "100%",
  },
});

export default function Itemlist() {
  const classes = useStyles();

  const [item, setItem] = useState({
    ...itemobj,
    code: "",
    newcode: "",
  })

  const [loading, setLoading] = useState(false)

  //Read the item list from database and put it in hook, run once only
  const [itemlist, setItemlist] = useState([]);
  const [itemlistopen, setItemlistopen] = useState(false); //The Item Selection List
  useEffect(() => {
    //console.log(itemlist);
    let reading = true;
    if (itemlist.length===0) {
      const readdb = async () => {
        let templist = [];
        const q = query(
          collection(db, "items"),
          where('brand', 'in', permission.orderinput.specificitemlist.admin),
        );

        const dataprocess = (querySnapshot) => {
          querySnapshot.forEach((singleitem) => {
            let brandcheck = permission.orderinput.specificitemlist.admin.findIndex(brand=>brand===singleitem.data().brand)
            if (brandcheck>-1) {
              let iteminfo = {
                ...item,
                ...singleitem.data(),
                code: singleitem.id,
                display_group: singleitem.data().brand === "g2h" ? `Other Charge - ${singleitem.data().category.toUpperCase()}` : `${singleitem.data().brand.toUpperCase()} - ${singleitem.data().category.toUpperCase()}`,
              };
              templist.push(iteminfo);
            }
          });
          if (reading) {
            let sortedlist = itemlistsorting(templist);
            //console.log(sortedlist);
            setItemlist(sortedlist);
          }
        }

        const fetching = async () => {
          const querySnapshot = await datafetching(q)
          if (!querySnapshot.empty) { dataprocess(querySnapshot) }
        }
        fetching();

      };
      readdb();
    }
    return () => {
      reading = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [update, setUpdate] = useState(false)
  useEffect(()=>{
    if ((item.code) && (update)) {
        let writing=true;
        const {code, newcode, display_group, ...newitemobj} = item;
          //let id;
          //newcode ? id = newcode : id = code;
          const writedb = async () => {
            try {
              await setDoc(doc(db, "items", newcode), newitemobj)
              if (writing) {
                //console.log("Item added!")
                setUpdate(false)
                setItem({
                  ...itemobj,
                  code: "",
                  newcode: "",
                })
                let newitemlist = itemlistsorting([...itemlist,
                {
                  ...newitemobj,
                  code: newcode,
                  newcode: "",
                  display_group: item.brand === "g2h" ? `Other Charge - ${item.category.toUpperCase()}` : `${item.brand.toUpperCase()} - ${item.category.toUpperCase()}`,
                }])
                //console.log(newitemlist)
                setItemlist(newitemlist)
                setLoading(false)
              }
            }  catch (e) {
              console.log(e);
            }
          }
          writedb();
        return () => {
          writing = false;
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update])

  const handleChange = (prop) => (e) => {
    if (prop === "total") {
      setItem({
        ...item,
        inventory: {
          ...item.inventory,
          total: parseInt(e.target.value),
        },
      })
    } else if (prop === "newcode") {
      setItem({
        ...item,
        newcode: e.target.value,
      })
    } else {
      setItem({
        ...item,
        [prop]: prop==="cost" || prop === "dsp" || prop === "rsp" ? parseFloat(e.target.value) : e.target.value,
      })
    }
  }

  return (
    <Box className={classes.container}>
      <Stack direction="column" alignItems="stretch" spacing={4}>
        <Typography paragraph variant="h1" align="center" sx={{ my: 4 }}>
          Add New Item
        </Typography>
        <Stack>
          <Autocomplete
            className={classes.lfield}
            id={"item"}
            open={itemlistopen}
            onOpen={() => { setItemlistopen(true) }}
            onClose={() => { setItemlistopen(false) }}
            value={item.code ? {...item} : null} //The value selected will be shown in the menu. The object "item" is put here
            isOptionEqualToValue={(option, value) => option.code === value.code} //The attribute used to identified the selection - here is the "code"
            getOptionLabel={(option) => option.name || ""} //Display name ONLY. Nothing to do with the value
            options={itemlist} //Selection list
            groupBy={(option) => option.display_group} //Category by specific attribute
            onChange={(e, value) => setItem({...value})}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item Selection"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {itemlist.length===0 ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <Paper variant="outlined" className={classes.paperstyle}>
          <Stack justifyContent="flex-start" spacing={4}>
            <TextField
              className={classes.sfield}
              id="code"
              label="Code"
              variant="outlined"
              value={item.newcode ? item.newcode : item.code}
              onChange={handleChange("newcode")}
            />
            <TextField
              className={classes.mfield}
              id="brand"
              label="Brand name"
              variant="outlined"
              value={item.brand}
              onChange={handleChange("brand")}
            />
            <TextField
              className={classes.mfield}
              id="category"
              label="Category"
              variant="outlined"
              value={item.category}
              onChange={handleChange("category")}
            />
            <TextField
              className={classes.mfield}
              id="name"
              label="Name"
              variant="outlined"
              value={item.name}
              onChange={handleChange("name")}
            />
            <TextField
              className={classes.mfield}
              id="cname"
              label="Chinese Name"
              variant="outlined"
              value={item.cname}
              onChange={handleChange("cname")}
            />
            <TextField
              className={classes.lfield}
              id="description"
              label="Description"
              variant="outlined"
              value={item.description}
              onChange={handleChange("description")}
            />
            <TextField
              className={classes.lfield}
              id="cdescription"
              label="Chinese Description"
              variant="outlined"
              value={item.cdescription}
              onChange={handleChange("cdescription")}
            />
            <TextField
              className={classes.sfield}
              id="cost"
              label="Cost"
              type="number"
              variant="outlined"
              value={item.cost}
              onChange={handleChange("cost")}
            />
            <TextField
              className={classes.sfield}
              id="dsp"
              label="DSP"
              type="number"
              variant="outlined"
              value={item.dsp}
              onChange={handleChange("dsp")}
            />
            <TextField
              className={classes.sfield}
              id="rsp"
              label="RSP"
              type="number"
              variant="outlined"
              value={item.rsp}
              onChange={handleChange("rsp")}
            />
            <TextField
              className={classes.mfield}
              id="target"
              label="Target"
              variant="outlined"
              value={item.target}
              onChange={handleChange("target")}
            />
            <TextField
              className={classes.sfield}
              id="stocks"
              label="Stocks"
              type="number"
              variant="outlined"
              value={item.inventory.total}
              onChange={handleChange("total")}
            />
          </Stack>
        </Paper>
        {
          itemlist &&
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={4}
            sx={{ py: "32px" }}
          >
            <Button variant="contained" onClick={()=>setItem({...itemobj, code:"",})} disableElevation>
              RESET
            </Button>
            <Button
              variant="contained"
              onClick={()=>{
                setUpdate(true)
                setLoading(true)
                }}
              disabled={ itemlist.filter((singleitem)=>singleitem.code===item.newcode).length>0 || !item.newcode ? true : false }
              disableElevation
            >
              ADD
            </Button>
          </Stack>
        }
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Stack>
    </Box>
  )
}
