import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { makeStyles } from '@mui/styles'
import {   
    Paper,
    Box,
    Typography,
    Stack,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button, 
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { db } from '../../firebase/initfirebase'
import { collection, query, orderBy } from "firebase/firestore"
import { datafetching, inventoryupdate, itemlistsorting } from "../../firebase/managedb";
import { permission } from "../listsnobjects"

const useStyles = makeStyles({
    container: {
      width: "100%",
      "@media (min-width:600px)": {
        width: "90%",
      },
      "@media (min-width:900px)": {
        width: "80%",
      },
      "@media (min-width:1200px)": {
        width: "60%",
      },
    },
    paperstyle: {
      padding: "16px",
      "@media (min-width:600px)": {
        padding: "32px",
      },
    },
    mfield: {
      width: "100%",
      "@media (min-width:600px)": {
        width: "300px",
      },
    },
    diaglogoutlinedbtn: {
      color: "#739c70",
      borderRadius: "15px",
      backgroundColor: "#ffffff",
      boxShadow: "none",
      border: "2px solid #739c70",
      width: "200px",
      "&:hover": {
        border: "3px solid #739c70",
        backgroundColor: "#ffffff",
        boxShadow: "none",
      },
    },
    diaglogcontainedbtn: {
      color: "#ffffff",
      borderRadius: "15px",
      backgroundColor: "#739c70",
      boxShadow: "none",
      border: "2px solid #739c70",
      width: "200px",
      "&:hover": {
        backgroundColor: "#5c755a",
      },
    },
  })

export default function Inventory({prop}) {
    let navigate = useNavigate();
    const classes = useStyles();
    const {type, lang} = prop;

    const [brand, setBrand] = useState();
    const [stocks, setStocks] = useState();
    useEffect (()=>{
        let reading = true;
        const readdb = async () => {
          let templist = [];
          const q = query(
            collection(db, "items"),
            orderBy("brand"),
            orderBy("category"),
            orderBy("name")
          );

          const dataprocess = (querySnapshot) => {
            querySnapshot.forEach((item) => {
              let iteminfo = {
                itemid: item.id,
                name: item.data().name,
                cname: item.data().cname,
                brand: item.data().brand,
                category: item.data().category,
                inventory: item.data().inventory,
                stocktake: item.data().stocktake,
                display_group: item.data().brand === "g2h" ? `Other Charge - ${item.data().category.toUpperCase()}` : `${item.data().brand.toUpperCase()} - ${item.data().category.toUpperCase()}`,
              };
              templist.push(iteminfo);
            });
            if (reading) {
                let sortedlist = itemlistsorting(templist);
                setStocks(sortedlist);
            }
          }

          const fetching = async () => {
            const querySnapshot = await datafetching(q)
            if (!querySnapshot.empty) { dataprocess(querySnapshot) }
          }
          fetching();
        };
        readdb();
        return () => {
          reading = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [dialogbox, setDialogbox] = useState({
      dbopen: false,
      id: '',
      name: '',
      currentqty: '',
      newqty: '',
    })

    const [dbupdate, setDbupdate] = useState(false)
    useEffect (()=>{
      if (dbupdate) {
        let updating = true;
        let newbalance = [{
          id: dialogbox.id,
          qty: parseInt(dialogbox.newqty),
        },]
        inventoryupdate(newbalance).then(
          (res) => {
            //console.log(res);
            if (updating) {
              let itemindex = stocks.findIndex(item=>item.itemid===newbalance[0].id);
              //console.log(stocks[itemindex])
              if (itemindex > -1) {
                let newitemobj = {
                  ...stocks[itemindex],
                  inventory: {
                    ...stocks[itemindex].inventory,
                    total: newbalance[0].qty,
                  }
                }
                let updateitems = [
                  ...stocks.slice(0, itemindex),
                  newitemobj,
                  ...stocks.slice(itemindex+1),
                ];
                //console.log(updateitems);
                setStocks(updateitems);
              }
              setDbupdate(false);
              setDialogbox({
                dbopen: false,
                id: '',
                name: '',
                currentqty: '',
                newqty: '',
              });
            };
          },
          (err) => {console.log(err);}
        )
        return () => {
          updating = false;
        };
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dbupdate])

    const handlebrandchange = (e) => {
      let value = e.target.value;
      setBrand(value)
    }
    
    const handleEdit = (id) => () => {
      const name = lang==="en" ? stocks.filter(stock=>stock.itemid===id)[0].name : stocks.filter(stock=>stock.itemid===id)[0].cname;
      const currentqty = stocks.filter(stock=>stock.itemid===id)[0].inventory.total
      setDialogbox({
        dbopen: true,
        id,
        name,
        currentqty,
        newqty: currentqty,
      })
     //console.log(id)
    }

    const handleChange = (e) => {
      setDialogbox({
        ...dialogbox,
        newqty: e.target.value,
      })
    }

    const handleClose = () => {
      setDialogbox({
        dbopen: false,
        id: '',
        name: '',
        currentqty: '',
        newqty: '',
      })
    }
    
  //Single field display template
  const Singlefielddisplay = ({ content }) => {
    return (
      <Stack direction="row" justifyContent="flex-start">
        <Typography variant="body1" sx={{ fontWeight: "bold", mr: "8px" }}>
          {content.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color:
              content.value === "overdue"
                ? "#FF0000"
                : content.value === "delivery"
                ? "#0000FF"
                : "#000000",
          }}
        >
          {content.value}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box className={classes.container}>
      <Stack direction="column" alignItems="stretch" spacing={4}>
          <Typography paragraph variant="h1" align="center" sx={{ my: 4 }}>
          { lang==="en" ? "INVENTORY" : "存貨一覽" }
          </Typography>
          {
            !stocks ?
              <Stack alignItems="center" spacing={4}>
                <CircularProgress color="inherit"/>
              </Stack>
              :
              <Stack spacing={2}>
                <FormControl className={classes.mfield}>
                  <InputLabel id="brand">Brand</InputLabel>
                    <Select
                      labelId="brand"
                      id="brand"
                      value={brand ? brand : ""}
                      label="Brand"
                      onChange={handlebrandchange}
                    >
                      {
                        //stocks.filter(el=>el.itemid==="sublist"&&el.brand!=="Other Charge")
                        permission.orderinput.specificitemlist[type]
                        .map((brand, index)=>
                          <MenuItem key={index} value={brand} >
                            {brand}
                          </MenuItem>
                        )
                      }
                    </Select>
                </FormControl>
                {
                  brand &&
                  stocks.filter(stock=>stock.brand===brand&&stock.itemid!=="sublist")
                  .map((stock, index)=>
                      <Paper key={index} variant="outlined" className={classes.paperstyle}>
                          <Stack justifyContent="flex-start" spacing={1}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <Singlefielddisplay
                              content={{
                                  title: lang==="en" ? "Name:" : "貨品名稱:",
                                  value: lang === "en" ? stock.name : stock.cname,
                              }}
                              />
                              {
                                permission["inventory"]["edit"][type] && 
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={handleEdit(stock.itemid)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </Stack>
                              <Singlefielddisplay
                                content={{
                                    title: lang==="en" ? "Brand:" : "品牌:",
                                    value: stock.brand,
                                }}
                              />
                              <Singlefielddisplay
                              content={{
                                  title: lang==="en" ? "ID:" : "貨品編號:",
                                  value: stock.itemid,
                              }}
                              />
                              <Singlefielddisplay
                              content={{
                                  title: lang==="en" ? "category:" : "種類:",
                                  value: stock.category,
                              }}
                              />
                              <Singlefielddisplay
                              content={{
                                  title: lang==="en" ? "Quantity:" : "數量:",
                                  value: stock.inventory.total,
                              }}
                              />
                          </Stack>
                      </Paper>
                  )
                }
              </Stack>
          }
          <Paper elevation={0} className={classes.paperstyle}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{ py: "16px" }}
              >
                <Button
                  variant="contained"
                  onClick={() => navigate("/orderlist/")}
                  disableElevation
                >
                  { lang==="en" ? "Back" : "返回" }
                </Button>
              </Stack>
          </Paper>
          <Box>
            <Dialog open={dialogbox.dbopen} onClose={handleClose}>
              <DialogTitle>
                Update the stock quantity
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                    color: "#000000",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Stack spacing={4} sx={{ my: "16px" }}>
                  <Singlefielddisplay
                    content={{
                        title: lang==="en" ? "Name:" : "貨品名稱:",
                        value: dialogbox.name,
                    }}
                  />
                  <Singlefielddisplay
                    content={{
                        title: lang==="en" ? "ID:" : "貨品編號:",
                        value: dialogbox.id,
                    }}
                  />
                  <Singlefielddisplay
                    content={{
                        title: lang==="en" ? "Current record:" : "現存貨紀錄:",
                        value: dialogbox.currentqty,
                    }}
                  />
                  <TextField
                    className={classes.mfield}
                    id="newqty"
                    label="New Quantity"
                    placeholder = {lang==="en" ? "New Quantity" : "更新數量"}
                    type="number"
                    variant="outlined"
                    value={dialogbox.newqty}
                    onChange={handleChange}
                  />
                </Stack>
              </DialogContent>
              <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  spacing={{ xs: 2, sm: 4 }}
                >
                  <Button
                    className={classes.diaglogcontainedbtn}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.diaglogcontainedbtn}
                    onClick={()=>{setDbupdate(true)}}
                  >
                    Update
                  </Button>
                </Stack>
              </DialogActions>
            </Dialog>
          </Box>
      </Stack>
    </Box>
  )
}
