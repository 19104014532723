import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    Paper,
    Box,
    Typography,
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    MenuItem,
    FormControl,
    TextField,
    IconButton,
    Select,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/initfirebase";
import {
    collection,
    getDocs,
    query,
    orderBy,
    addDoc,
} from "firebase/firestore";

const useStyles = makeStyles({
    container: {
        width: "100%",
        "@media (min-width:600px)": {
            width: "90%",
        },
        "@media (min-width:900px)": {
            width: "80%",
        },
        "@media (min-width:1200px)": {
            width: "60%",
        },
    },
    paperstyle: {
        padding: "16px",
        width: "90%",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media (min-width:600px)": {
            padding: "16px",
            height: "600",
        },
    },
    buttonstyle: {
        padding: "32px",
        width: "50px",
        height: "50px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: "pointer",
            background: "#50C878",
            color: "#ffffff",
        },
    },
    deletebutton: {
        '&:hover': {
            cursor: "pointer",
            color: "#FF0000",
        },
    },
    pagebutton: {
        padding: "8px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: "pointer",
            background: "#50C878",
            color: "#ffffff",
        },
    },
    sfield: {
        width: "200px",
    },
    mfield: {
        width: "100%",
        "@media (min-width:600px)": {
            width: "300px",
        },
    },
    lfield: {
        width: "100%",
        "@media (min-width:600px)": {
            width: "500px",
        },
    },
    fullfield: {
        width: "100%",
    },
    diaglogoutlinedbtn: {
        color: "#739c70",
        borderRadius: "15px",
        backgroundColor: "#ffffff",
        boxShadow: "none",
        border: "2px solid #739c70",
        width: "200px",
        "&:hover": {
            border: "3px solid #739c70",
            backgroundColor: "#ffffff",
            boxShadow: "none",
        },
    },
    diaglogcontainedbtn: {
        color: "#ffffff",
        borderRadius: "15px",
        backgroundColor: "#739c70",
        boxShadow: "none",
        border: "2px solid #739c70",
        width: "130px",
        "&:hover": {
            backgroundColor: "#5c755a",
        },
    },
});

export default function NewSales() {
    const classes = useStyles();
    let navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [bpage, setBpage] = useState(1);
    const [clpage, setClpage] = useState(1);
    const [defaultsales, setDefaultsales] = useState("Doris Tse")
    const [popup, setPopup] = useState(false);
    const [total, setTotal] = useState(0)
    const [payment, setPayment] = useState("cash")
    const [remarks, setRemarks] = useState("")
    const [deletepopup, setDeletepopup] = useState({
        open: false,
        index: "",
        name: "",
    })
    const [finishpopup, setFinishpopup]=useState(false)
    const [order, setOrder] = useState(
        {
            itemid: '',
            name: '',
            brand: '',
            sales: '',
            price: '',
            qty: '',
            clearance: false,
        }
    )
    const [orderlist, setOrderlist] = useState([])

    //Read the item list from database and put it in hook
    const [itemlist, setItemlist] = useState();
    useEffect(() => {
        let reading = true;
        const readdb = async () => {
            let templist = [];
            const q = query(
                collection(db, "items"),
                orderBy("brand"),
                orderBy("category"),
                orderBy("name")
            );
            try {
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((item) => {
                    let iteminfo = {
                        itemid: item.id,
                        name: item.data().name,
                        brand: item.data().brand,
                        psp: item.data().psp,
                        category: item.data().category,
                        target: item.data().target,
                        barcode: item.data().barcode,
                    };
                    templist.push(iteminfo);
                });
                if (reading) {
                    setItemlist(templist);
                }
            } catch (e) {
                //error handling
                console.log(e);
            }
        };
        readdb();
        return () => {
            reading = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [update, setUpdate] = useState(false)
    useEffect(()=>{
      if ((update)) {
          let writing=true;
          let now = new Date();
          let record = {timestamp: now, orders: [...orderlist], payment, total, remarks}
          console.log (record)
          //const {code, ...newitemobj} = item
            const writedb = async () => {
              try {
                await addDoc(collection(db, "ps"), record)
                if (writing) {
                  console.log("Item added!")
                  setUpdate(false)
                  //Reset
                  setValue(0)
                  setDefaultsales("Doris Tse")
                  setTotal(0)
                  setPayment("cash")
                  setRemarks("")
                  setOrder(
                    {
                        itemid: '',
                        name: '',
                        brand: '',
                        sales: '',
                        price: '',
                        qty: '',
                        clearance: false,
                    }
                  )
                  setOrderlist([])
                }
              }  catch (e) {
                console.log(e);
              }
            }
            writedb();
          return () => {
            writing = false;
          };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[update])

    const listhandling = (newlist) => {

        let otherlist = newlist.filter((item)=>item.brand!=="QCHEFS")
        //console.log(otherlist)
        let qclist = newlist.filter((item)=>item.brand==="QCHEFS")
        console.log(qclist)

        let gpqclist = []
        if (qclist.length>0) {

            let newqclist = []
            //Extract and initialize
            qclist.forEach((qcitem)=>{
                let price = itemlist.filter(item=>item.itemid===qcitem.itemid)[0].psp
                for (let i = 0; i < qcitem.qty; i++) {
                    newqclist.push({...qcitem, qty: 1, price})
                }
            })
            console.log(newqclist)
            //Sort the QC Array
            newqclist.sort((a, b)=>a.name-b.name)
            newqclist.sort((a, b)=>b.price-a.price)

            //Calculate the No. of free item
            let NFreeItem = Math.floor(newqclist.length/3)
            for (let i = 0; i < NFreeItem; i++) {
                let index = 3*(i+1)-1
                newqclist[index].price=0
            }
            console.log(newqclist)

            //Grouping
            let freelist = [...newqclist.filter(item=>item.price===0)]
            newqclist = [...newqclist.filter(item=>item.price!==0)]
            let arraylength = newqclist.length
            while (arraylength>0) {
                let id = newqclist[0].itemid
                let sales = newqclist[0].sales
                let newqty = newqclist.filter(item=>item.itemid===id && item.sales===sales).length
                gpqclist.push({...newqclist[0], qty: newqty})
                console.log(gpqclist)
                newqclist = [...newqclist.filter(item=>item.itemid!==id || item.sales!==sales)]
                console.log(newqclist)
                arraylength = newqclist.length
            }
            gpqclist = [...gpqclist, ...freelist]
            console.log(gpqclist)
        }
        let fulllist = [...gpqclist, ...otherlist]
        return fulllist
    }

    const totalcal = (list) => {
        let totalamount = 0
        list.forEach(item=>{
            totalamount += parseInt(item.price*item.qty)
        })
        console.log (totalamount)
        return totalamount
    }

    //Event Handler 

    const handleChange = (e, newValue) => {
        //console.log(newValue);
        setValue(newValue);
        setBpage(1);
        setClpage(1);
    };

    const handleReset = () => {
        setValue(0)
        setDefaultsales("Doris Tse")
        setTotal(0)
        setPayment("cash")
        setRemarks("")
        setOrder(
          {
              itemid: '',
              name: '',
              brand: '',
              sales: '',
              price: '',
              qty: '',
          }
        )
        setOrderlist([])
    }

    const itemSelected = (item) => (e) => {
        console.log (value)
        setPopup(true)
        let psp = item.psp
        let item_name = item.name
        if (value > 5) { //Clearance
            item_name = `CLEARANCE - ${item.name}`
            if ((item.itemid === "bo-tan") || (item.category !== "bogaprotect")) {psp = 33}
            else {psp = 49.5}
        }
        setOrder({
            itemid: item.itemid,
            name: item_name,
            brand: item.brand,
            sales: defaultsales,
            price: psp,
            qty: 1,
            clearance: value > 5 ?  true : false,
        })
    }
    const handleClose = () => {
        setPopup(false);
        setOrder(
            {
                itemid: '',
                name: '',
                brand: '',
                sales: '',
                price: '',
                qty: '',
                clearance: false,
            }
        )
    }
    const handleDGChange = (section) => (e) => {
        if (section === "sales") { setOrder({ ...order, sales: e.target.value }) }
        if (section === "qty") { setOrder({ ...order, qty: e.target.value < 1 ? 1 : e.target.value }) }
        if (section === "price") { setOrder({ ...order, price: e.target.value }) }
    }
    const handleDGConfirm = () => {
        console.log(order)
        setPopup(false)
        let newlist = [...orderlist, order]
        console.log(newlist)

        //Dealing with the list
        let updatedlist = [...listhandling(newlist)]

        //Calculate the total amount
        let totalamount = totalcal (updatedlist)

        //Update the hook
        setOrderlist([...updatedlist])
        setTotal(totalamount)
    }

    const deleteClicked = (index) => (e) => {
        console.log(index)
        let name = orderlist[index].name
        setDeletepopup({open: true, index, name})
    }

    const deleteClose = () => {
        setDeletepopup({open: false, index: "", name: ""})
    }

    const deleteConfirm = () => {
        let templist = [...orderlist]
        templist.splice(deletepopup.index, 1)
        //Manage the list
        let updatedlist = [...listhandling(templist)]
        //Calculate the total amount
        let totalamount = totalcal (updatedlist)
        //Update hooks
        setOrderlist([...updatedlist])
        setTotal(totalamount)
        setDeletepopup({open: false, index: "", name: ""})
    }

    const handleFinish =() => {
        //let record = {orders: [...orderlist], ...payment, ...total, ...remarks}
        //console.log (record)
        setUpdate(true)
        setFinishpopup(false)
    }

    return (
        <Box className={classes.container}>
            <Stack direction="column">
                <Box sx={{ my: "16px" }}>
                    <FormControl className={classes.sfield}>
                        <InputLabel id="defaultsales">Sales</InputLabel>
                        <Select
                            labelId="DefaultSales-label"
                            id="Default Sales"
                            value={defaultsales}
                            label="Sales"
                            onChange={(e) => setDefaultsales(e.target.value)}
                        >
                            <MenuItem value={"Doris Tse"}>
                                Doris Tse
                            </MenuItem>
                            <MenuItem value={"Stephanie"}>
                                Stephanie
                            </MenuItem>
                            <MenuItem value={"Tung"}>
                                Tung
                            </MenuItem>
                            <MenuItem value={"Kay"}>
                                Kay
                            </MenuItem>
                            <MenuItem value={"Vicky"}>
                                Vicky
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box >
                    <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="Brands">
                        <Tab label="QCHEFS" id="QCHEFS" />
                        <Tab label="Bogar" id="BOGAR" />
                        <Tab label="burbur" id="BURBUR" />
                        <Tab label="MycAni" id="MYCANI" />
                        <Tab label="Sopra" id="SOPRA" />
                        <Tab label="PhiloPet" id="PHILOPET" />
                        <Tab label="CLEARANCE" id="CLEARANCE" />
                    </Tabs>
                </Box>
                <Paper className={classes.paperstyle} elevation={1} >
                    {
                        //QC
                        itemlist && value === 0 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "QCHEFS") && (item.category === "Basic"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "QCHEFS") && (item.category === "Advance"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "QCHEFS") && (item.category === "Cat"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                        </Stack>
                    }
                    {
                        //Bogar
                        itemlist && value === 1 && bpage === 1 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) === "bo-so") && (item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) === "bo-c-") && (item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) !== "bo-ch") && (item.target === "Cats"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                                justifyContent="center"
                            >
                                <Box className={classes.pagebutton} onClick={()=>setBpage(2)}>
                                    <Typography variant="body2">
                                        Next Page
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    }
                    {
                        //Bogar
                        itemlist && value === 1 && bpage === 2 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) === "bo-cs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.itemid === "bo-tan"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.itemid === "bo-ear") || (item.itemid === "bo-derma") || item.itemid.substring(0,5) === "bo-cl")
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                                justifyContent="center"
                            >
                                <Box className={classes.pagebutton} onClick={()=>setBpage(1)}>
                                    <Typography variant="body2">
                                        Prev Page
                                    </Typography>
                                </Box>
                                <Box className={classes.pagebutton} onClick={()=>setBpage(3)}>
                                    <Typography variant="body2">
                                        Next Page
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    }
                    {
                        //Bogar
                        itemlist && value === 1 && bpage === 3 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.category === "bogadental") && (item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.category === "bogadental") && (item.target === "Cats"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                                justifyContent="center"
                            >
                                <Box className={classes.pagebutton} onClick={()=>setBpage(2)}>
                                    <Typography variant="body2">
                                        Prev Page
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    }
                    {
                        //BURBUR
                        itemlist && value === 2 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BURBUR" && item.category === "Supplement"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BURBUR" && item.category === "Home Shampoo"))
                                        //.filter(item=>(item.itemid !== "bb-h-mm" && item.itemid !== "bb-h-2p"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BURBUR" && item.category === "Home Conditioner"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                        </Stack>
                    }
                    {
                        //MycAni
                        itemlist && value === 3 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "MYCANI" && item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "MYCANI" && item.target === "cats" && item.itemid !== "ma-gec"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.itemid === "ma-gec"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                        </Stack>
                    }
                    {
                        //Sopra
                        itemlist && value === 4 &&
                        <Stack spacing={4} sx={{ py: 4 }}>
                            {
                                itemlist.filter(item => (item.brand === "SOPRA"))
                                    .map((item, index) =>
                                        <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                {item.name}
                                            </Typography>
                                        </Paper>
                                    )
                            }
                        </Stack>
                    }
                    {
                        //PP
                        itemlist && value === 5 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "PHILOPET" && item.category !== "Conditioner"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                        </Stack>
                    }

                    {
                        //Clearance
                        itemlist && value === 6 && clpage === 1 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) === "bo-so") && (item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) === "bo-c-") && (item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.itemid.substring(0,5) !== "bo-ch") && (item.target === "Cats"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                                justifyContent="center"
                            >
                                <Box className={classes.pagebutton} onClick={()=>setClpage(2)}>
                                    <Typography variant="body2">
                                        Next Page
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    }

{
                        //Clearance
                        itemlist && value === 6 && clpage === 2 &&
                        <Stack direction="column" spacing={4} sx={{ py: 4 }}>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.itemid === "bo-tan"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.category === "bogadental") && (item.target === "dogs"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                            >
                                {
                                    itemlist.filter(item => (item.brand === "BOGAR") && (item.category === "bogadental") && (item.target === "Cats"))
                                        .map((item, index) =>
                                            <Paper className={classes.buttonstyle} elevation={1} key={index} onClick={itemSelected(item)}>
                                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                    {item.name}
                                                </Typography>
                                            </Paper>
                                        )
                                }
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={4}
                                justifyContent="center"
                            >
                                <Box className={classes.pagebutton} onClick={()=>setClpage(1)}>
                                    <Typography variant="body2">
                                        Prev Page
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    }

                </Paper>
            </Stack>
            {
                //Summary Table
            }
            <Stack                 
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{ py: 4 }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{color: "#000000"}}>Brand</TableCell>
                                <TableCell align="center" sx={{color: "#000000"}}>Item</TableCell>
                                <TableCell align="center" sx={{color: "#000000"}}>Quantity</TableCell>
                                <TableCell align="center" sx={{color: "#000000"}}>Unit Price</TableCell>
                                <TableCell align="center" sx={{color: "#000000"}}>Total Price</TableCell>
                                <TableCell align="center" sx={{color: "#000000"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {orderlist.map((order, index) => (
                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row" sx={{color: "#000000"}}>
                                {order.brand}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{color: "#000000"}}>
                                {order.name}
                            </TableCell>
                            <TableCell align="center" sx={{color: "#000000"}}>{order.qty}</TableCell>
                            <TableCell align="center" sx={{color: "#000000"}}>{`$${order.price}`}</TableCell>
                            <TableCell align="center" sx={{color: "#000000"}}>{`$${order.qty*order.price}`}</TableCell>
                            <TableCell align="center" sx={{color: "#000000"}}><DeleteIcon onClick={deleteClicked(index)} className={classes.deletebutton}/></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            {
                //Payment
            }
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={4}
            >
                <Box>
                    <FormControl className={classes.sfield}>
                        <InputLabel id="payment">Payment</InputLabel>
                        <Select
                            labelId="DefaultSales-label"
                            id="Payment"
                            value={payment}
                            label="Payment"
                            onChange={(e) => setPayment(e.target.value)}
                        >
                            <MenuItem value={"cash"}>
                                Cash
                            </MenuItem>
                            <MenuItem value={"credit"}>
                                Credit Card
                            </MenuItem>
                            <MenuItem value={"octopus"}>
                                Octopus
                            </MenuItem>
                            <MenuItem value={"payme"}>
                                PayMe
                            </MenuItem>
                            <MenuItem value={"fps"}>
                                FPS
                            </MenuItem>
                            <MenuItem value={"wechat"}>
                                WeChat
                            </MenuItem>
                            <MenuItem value={"alipay"}>
                                AliPay
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {`Total: $${parseInt(total)}`}
                    </Typography>
                </Box>
            </Stack>
            {
                //Remarks
            }
            <Stack sx={{py: 4}}>
                <TextField
                    className={classes.lfield}
                    id="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    variant="outlined"
                    value={remarks}
                    onChange={(e)=>setRemarks(e.target.value)}
                />
            </Stack>
            {
                //Button
            }
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={4}
                sx={{ py: 4 }}
            >
                <Button
                    variant="contained"
                    onClick={() => navigate("/pos")}
                    disableElevation
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={handleReset}
                    disableElevation
                >
                    RESET
                </Button>
                <Button
                    variant="contained"
                    onClick={()=>setFinishpopup(true)}
                    disableElevation
                >
                    Finished
                </Button>
            </Stack>

            {
                //Popup Finish Dialog
            }
            <Dialog open={finishpopup} onClose={()=>setFinishpopup(false)}>
                <DialogTitle>
                    {`Confirm the order?`}
                </DialogTitle>
                <DialogActions sx={{ px: 3, pb: 3, justifyContent: "center" }}>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        spacing={{ xs: 2, sm: 4 }}
                    >
                        <Button
                            className={classes.diaglogcontainedbtn}
                            onClick={()=>setFinishpopup(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.diaglogcontainedbtn}
                            onClick={handleFinish}
                        >
                            CONFIRM
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            {
                //Popup Delete Dialog
            }
            <Dialog open={deletepopup.open} onClose={deleteClose}>
                <DialogTitle>
                    {`Deleting the input ${deletepopup.name}`}
                </DialogTitle>
                <DialogActions sx={{ px: 3, pb: 3, justifyContent: "center" }}>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        spacing={{ xs: 2, sm: 4 }}
                    >
                        <Button
                            className={classes.diaglogcontainedbtn}
                            onClick={deleteClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.diaglogcontainedbtn}
                            onClick={deleteConfirm}
                        >
                            DELETE
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            {
                //PopUp
            }
            <Dialog open={popup} onClose={handleClose}>
                <DialogTitle>
                    {
                        //order.clearance ? (`Clearance: ${order.name}`) : (`${order.name}`)
                        order.name
                    }
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 16,
                            top: 16,
                            color: "#000000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={4} sx={{ my: "16px" }}>
                        <FormControl className={classes.sfield}>
                            <InputLabel id="sales">Sales</InputLabel>
                            <Select
                                labelId="Sales-label"
                                id="Sales"
                                value={order.sales}
                                label="Sales"
                                onChange={handleDGChange("sales")}
                            >
                                <MenuItem value={"Doris Tse"}>
                                    Doris Tse
                                </MenuItem>
                                <MenuItem value={"Stephanie"}>
                                    Stephanie
                                </MenuItem>
                                <MenuItem value={"Tung"}>
                                    Tung
                                </MenuItem>
                                <MenuItem value={"Kay"}>
                                    Kay
                                </MenuItem>
                                <MenuItem value={"Vicky"}>
                                    Vicky
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            className={classes.sfield}
                            id="qty"
                            label="Quantity"
                            placeholder="Quantity"
                            type="number"
                            variant="outlined"
                            value={order.qty}
                            onChange={handleDGChange("qty")}
                        />
                        <TextField
                            className={classes.sfield}
                            id="price"
                            label="Price"
                            placeholder="Price"
                            type="number"
                            variant="outlined"
                            value={order.price}
                            onChange={handleDGChange("price")}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3, justifyContent: "center" }}>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        spacing={{ xs: 2, sm: 4 }}
                    >
                        <Button
                            className={classes.diaglogcontainedbtn}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classes.diaglogcontainedbtn}
                            onClick={handleDGConfirm}
                            disabled={
                                !order.qty || !order.price
                                    ? true
                                    : false
                            }
                        >
                            CONFIRM
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={update}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}