import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { loginStatus } from './firebase/LoginProvider'
import { db } from './firebase/initfirebase'
import { doc, getDoc } from "firebase/firestore"
import Login from './components/Login'
import Dashboard from './components/Dashboard'
import Orderinput from './components/dashboard/Orderinput'
import Orderlist from './components/dashboard/Orderlist'
import Orderdetail from './components/dashboard/Orderdetail'
import Inventory from './components/dashboard/Inventory'
import Itemlist from './components/dashboard/Itemlist'
import POS from './components/pos/POS'
import NewSales from './components/pos/NewSales';
import History from './components/pos/History';
import { permission } from './components/listsnobjects'
import ProtectedRoute from './ProtectedRoute'

export default function App() {
  const { currentstatus, setCurrentstatus } = useContext(loginStatus) //Load the context login state
  const [loading, setLoading] = useState(false); //Loading status in login page
  useEffect(()=>{
    if (currentstatus.status) {
      setLoading(true);
      //Fetch the user info from database
      let docRef = doc(db, "users", currentstatus.uid)
      const userfetch = async () => {
        try{
            const user = await getDoc(docRef)
            if (user) {
              //console.log(user.data())
              setCurrentstatus({
                ...currentstatus,
                type: user.data().type,
                username: user.data().username,
              })
              setLoading(false);
            }
        } catch (e) { //error handling
          console.log(e)
        }
      }
      userfetch()
    }
    // eslint-disable-next-line
  },[currentstatus.status])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ !currentstatus.type ? <Login loadingstatus={{ loading, setLoading, }}/> : currentstatus.type==="pos" ? <Navigate to="/pos" replace /> : <Navigate to="/orderinput" replace /> } />
        <Route element={ <ProtectedRoute type={currentstatus.type} /> } >
          <Route element={ <Dashboard /> } >
            <Route element={ <ProtectedRoute type={currentstatus.type} redirectPath={'/orderlist'} permission={permission.orderinput}/> } >
              <Route path='orderinput' element={ <Orderinput prop={{type: currentstatus.type, lang: currentstatus.lang}} /> } />
            </Route>
            <Route path='orderlist' element={ <Orderlist prop={{type: currentstatus.type, lang: currentstatus.lang, username: currentstatus.username}} /> } />
            <Route path='orderlist/:orderid' element={ <Orderdetail prop={{type: currentstatus.type, lang: currentstatus.lang}} /> } />
            <Route path='inventory' element={ <Inventory prop={{type: currentstatus.type, lang: currentstatus.lang}} /> } />
            <Route element={ <ProtectedRoute type={currentstatus.type} redirectPath={'/orderlist'} permission={permission.itemlist}/> } >
              <Route path='itemlist' element={ <Itemlist prop={{type: currentstatus.type, lang: currentstatus.lang}} /> } />
            </Route>
            <Route element={ <ProtectedRoute type={currentstatus.type} redirectPath={'/orderlist'} permission={permission.pos}/> } >
              <Route path='pos' element={ <POS /> } />
              <Route path='pos/newsales' element={ <NewSales /> } />
              <Route path='pos/history' element={ <History /> } />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={ <Navigate to="/" replace /> } />
      </Routes>
    </BrowserRouter>
  );
}
