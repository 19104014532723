import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export default function ProtectedRoute({ type, redirectPath, permission, children }) {
    let returncomponent;
    if (!type) { //make sure user type has been loaded
        returncomponent = (<Navigate to="/" replace />);
    } else if ((!permission)||(permission[type])) {
        returncomponent = (children ? children : <Outlet />);
    } else {
        returncomponent = (<Navigate to={redirectPath} replace />);
    }
  return returncomponent;
}
