import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { makeStyles } from '@mui/styles'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Stack } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import { db } from '../../firebase/initfirebase'
import { collection, query, where, limit, limitToLast, orderBy, startAfter, endBefore } from "firebase/firestore"
import { tableheader, channellist, orderstatuslist, paystatuslist, permission } from "../listsnobjects"
import { dateconvert, dataupdate, datafetching } from "../../firebase/managedb"

const useStyles = makeStyles({
  container: {
      width: '100%',
      '@media (min-width:600px)': {
          width: "90%",
      },
  },
  sfield: {
    width: "200px",
  },
  mfield: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "300px",
    },
  },
  lfield: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "500px",
    },
  },
  fullfield: {
    width: "100%",
  },
  diaglogoutlinedbtn: {
    color: "#739c70",
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    border: "2px solid #739c70",
    width: "200px",
    "&:hover": {
      border: "3px solid #739c70",
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
  },
  diaglogcontainedbtn: {
    color: "#ffffff",
    borderRadius: "15px",
    backgroundColor: "#739c70",
    boxShadow: "none",
    border: "2px solid #739c70",
    width: "200px",
    "&:hover": {
      backgroundColor: "#5c755a",
    },
  },
})

export default function Orderlist({prop}) {
  const classes = useStyles()
  let navigate = useNavigate()
  const {type, lang, username} = prop

  //Hook for fetched database
  const [display_orders, setDisplay_orders] = useState();

  //Hook for pagination control
  const [page, setPage] = useState(0)
  const [pageaction, setPageaction] = useState()
  const [pagebutton, setPagebutton] = useState({
    prev: false,
    next: false,
  })
  const [batchrec, setBatchrec] = useState({
    first: '',
    last: '',
  })

  //Hook for database reading control
  const [dbreload, setDbreload] = useState(true)

  //Read the order collection from database once only in initialisation
  useEffect(()=>{
    let reading =true
    if (dbreload) {
      const readdb = async () => {
        
        //Read the database orders
        let role
        if (permission["orderlist"]["showdeliveredonly"][type]) {
          //Warehouse
          role = where("orderstatus", "in", ["packing", "packed", "delivery"])
        } else if ((type === "admin")||(type === "poweruser")) {
          //Admin
          role = ""
        } else {
          //Sales
          let sales = username.split(' ')
          role = where("sales", "==", sales[0])
        }

        let q
        //For Page 1 only
        if (page===0) {
           q = query(
            collection(db, "orders"),
            role && role,
            orderBy("orderdate", "desc"),
            limit(10)
          ) 
        } else {
          //For other pages
          q = query(
            collection(db, "orders"),
            role && role,
            orderBy("orderdate", "desc"),
            pageaction===1 ? startAfter(batchrec.last) : endBefore(batchrec.first), //Next is clicked / Previous is clicked
            pageaction===1 ? limit(10) : limitToLast(10), //Next is clicked / Previous is clicked
          )
        }

        //Process the data fetched
        const dataprocess = (querySnapshot) => {
          let templist = []
          querySnapshot.forEach((order) => {
            //Expiry check and update
            const expiry = (duedate) => {
              let exp = false
              let today = (Math.trunc(new Date()/86400000))*86400000 //Today at 0:00 am
              //console.log(`Today is ${today}. Due is ${duedate.toDate()}`)
              if (duedate.toDate() < today) {
                exp = true
                //console.log("Expired!")
              }
  
              //Indicate Overdue if it is expired
              if ((order.data().paymentstatus==="unsettled") && (exp)) {
                //Update the payment status in the database
                const updateobj = { paymentstatus: "overdue" }
                dataupdate("orders", order.id, updateobj).then((res)=>{
                  //console.log(res)
                }, (err) => {
                  console.log(err)
                })
                return "overdue"
              } else {
                return order.data().paymentstatus
              }
            }
  
            //Put the data into the hook
            let orderinfo = { 
              orderid: order.id, 
              orderdate: order.data().orderdate.toDate(),
              channels: order.data().channels,
              name: order.data().name,
              contact: order.data().contact,
              orderstatus: order.data().orderstatus,
              paymentstatus: expiry(order.data().paymentduedate),
              paymentduedate: order.data().paymentduedate.toDate(),
              paiddate: order.data().paiddate ? order.data().paiddate.toDate() : "-",
            }
            templist.push(orderinfo)
          })
          if (reading) {
            setDisplay_orders(templist);

            //Pagination
            setBatchrec({ //Record the first and last record
              first: querySnapshot.docs[0],
              last: querySnapshot.docs[querySnapshot.docs.length-1],
            })
            setPagebutton({ //Pagination button Enabled / Disabled
              prev: page > 0 ? true : false,
              next: querySnapshot.docs.length < 10 ? false : true,
            })
            setDbreload (false)
          }
        }

        //Readeing the database
        const readorderdb = async () => {
          const querySnapshot = await datafetching(q)
          if (!querySnapshot.empty) {
            //Data received
            dataprocess(querySnapshot)
          } else { 
            //No data received
            setPagebutton({...pagebutton, next: false,})
            if (page===0) {
              let templist = []
              let obj = {
                orderid: "", 
              };
              templist.push(obj)
              setDisplay_orders(templist)
            }
            setDbreload (false)
          }
        }
        readorderdb();
      }
      readdb()
    }
    return () => { reading = false }
    // eslint-disable-next-line
  }, [dbreload])

 //Display table
  const handleChangePage = (paginationbutton) => (event) => {
  let newPage = page + paginationbutton
  if (newPage < 0) {newPage = 0}
  setPage(newPage)
  setPageaction(paginationbutton)
  setDbreload(true)
  setDisplay_orders()
  };

  const handleClick = (orderid) => (event) => {
    navigate(`/orderlist/${orderid}`)
  }

  return (
    <Box className={classes.container}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableheader.map((tableheader, index) => (
                <TableCell
                  key={index}
                  align="center"
                  style={{ minWidth: index===2 ? 280: 100, color: "#000000" }}
                  //sortDirection={ sorting.col === tableheader.id ? sorting.order : false }
                >
                  <TableSortLabel
                    //active={sorting.col === tableheader.id}
                    //direction={sorting.col === tableheader.id ? sorting.order : "asc"}
                    //onClick={sortingHandler(tableheader.id)}
                  >
                    { lang==="en" ? tableheader.display : tableheader.cdisplay }
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {display_orders ? (
              display_orders[0].orderid ?
              (
              display_orders
                .map((order) => {
                  return (
                    <TableRow
                      hover
                      key={order.orderid}
                      onClick={handleClick(order.orderid)}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {dateconvert(order.orderdate)}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {
                          lang==="en" ?
                          channellist.filter((channel)=>channel.id===order.channels)[0].display
                          :
                          channellist.filter((channel)=>channel.id===order.channels)[0].cdisplay
                        }
                      </TableCell>
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {order.name}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {order.contact}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {
                          lang==="en" ?
                          orderstatuslist.filter((orderstatus)=>orderstatus.id===order.orderstatus)[0].display
                          :
                          orderstatuslist.filter((orderstatus)=>orderstatus.id===order.orderstatus)[0].cdisplay
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color:
                            order.paymentstatus === "overdue"
                              ? "#FF0000"
                              : "#000000",
                        }}
                      >
                        {
                          lang==="en" ?
                          paystatuslist.filter((paystatus)=>paystatus.id===order.paymentstatus)[0].display
                          :
                          paystatuslist.filter((paystatus)=>paystatus.id===order.paymentstatus)[0].cdisplay
                        }
                      </TableCell>
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {order.paymentstatus === "paid"
                          ? "-"
                          : dateconvert(order.paymentduedate)}
                      </TableCell>
                      <TableCell align="center" style={{ color: "#000000" }}>
                        {order.paiddate==="-" ? "-" : dateconvert(order.paiddate)}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ color: "#000000" }}
                    colSpan={8}
                  >
                    No Order Found!
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  style={{ color: "#000000" }}
                  colSpan={8}
                >
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        display_orders && (
        <Stack justifyContent="center" alignItems="center" direction="row" spacing={4}>
          <Box display={pagebutton.prev||page>0 ? "block" : "none"} onClick={handleChangePage(-1)} sx={{ px: 3, py: 3, justifyContent: "center", "&:hover": { cursor: "pointer" } }}>
            Prev
          </Box>
          <Box  sx={{px: 3, py: 3, justifyContent: "center"}} >
            Page {page + 1}
          </Box>
          <Box display={pagebutton.next ? "block" : "none"} onClick={handleChangePage(1)} sx={{ px: 3, py: 3, justifyContent: "center", "&:hover": { cursor: "pointer" } }}>
            Next
          </Box>
        </Stack>
      )}
    </Box>
  );
}
