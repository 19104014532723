import React from "react";
import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "./dashboard/Navbar";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    margin: "32px 16px",
  },
});

export default function Dashboard() {
  const classes = useStyles();
  return (
    <Box>
      <Navbar />
      <Box className={classes.container}>
        <Stack alignItems="center" spacing={4}>
          <Outlet />
        </Stack>
      </Box>
    </Box>
  );
}
