import { db, storage } from './initfirebase'
import { collection, getDoc, getDocs, doc, updateDoc, deleteDoc, writeBatch, getDocsFromCache, getDocsFromServer } from "firebase/firestore"
import { ref, deleteObject } from "firebase/storage";
import { permission } from '../components/listsnobjects'

export function dbcustomers() {
    return new Promise((resolve, reject) => { //Create the promise
        getDocs(collection(db, "customers")).then((customers)=>{
            resolve(customers)
        })
        .catch((err)=>{            
            reject(err.message)
        })
    })
}

export const datafetching = async (q) => {
  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot

    /*
    const querySnapshot = await getDocsFromCache(q);
    console.log(querySnapshot);
    if (!querySnapshot.empty) {
      return querySnapshot
    } else {
      try {
        const querySnapshot = await getDocsFromServer(q);
        console.log(querySnapshot);
        return querySnapshot
      } catch (e) {
        console.log(e);
        return false
      }
    }
    */
   
  } catch (e) {
    //error handling
    console.log(e);
    return e
  }
}

//Data update function
export const dataupdate = async (col, record, updateobj) => {
    const updateRef = doc(db, col, record)
    try {
      await updateDoc(updateRef, updateobj)
      return "Updated"
    } catch (e) { //error handling
      return e
    }
}

//Itemlist sorting
export const itemlistsorting = (itemlist) => {
  //console.log(itemlist)
  itemlist.sort((a, b) => {
    //1st sort by Brand + Category
    if (a.display_group.toUpperCase() > b.display_group.toUpperCase()) return 1;
    if (a.display_group.toUpperCase() < b.display_group.toUpperCase()) return -1;
    //2nd sort by product name
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    //3rd The special case in Bogar list
    if ((a.display_group.toUpperCase() === "BOGAR - BOGAPROTECT") && (a.display_group.toUpperCase() === b.display_group.toUpperCase())){
      if (a.name.length===18) { 
        if (a.name.substring(16,1)==="S") return 1
        else return -1
      }
      if (a.name.length===17) {
        if (a.name.substring(14,3)==="(S)") return -1
        if (a.name.substring(14,3)==="(L)") return 1
      }
    }
    return 0;
  })

  //Put the samples, marketing stuff and other charges at the end
  let sorted_items = itemlist.filter(item => (!item.display_group.includes("Other Charge - "))&&(!item.display_group.includes("MARKETING"))&&(!item.display_group.includes("SAMPLES")))
  let sorted_samples = itemlist.filter(item => item.display_group.includes("SAMPLES"))
  let sorted_marketing = itemlist.filter(item => item.display_group.includes("MARKETING"))
  let sorted_othercharges = itemlist.filter(item => item.display_group.includes("Other Charge - "))
  let newitemlist = [...sorted_items, ...sorted_samples, ...sorted_marketing, ...sorted_othercharges]

  //console.log(newitemlist)
  return newitemlist
}

//Function for deduct or add the delivering goods from the inventory record. Calculate the inventory balance
export const inventorycalculate = (itemlist, order, subtract) => {
  let balance = []
  itemlist.forEach((item)=>{
    //Group the same item
    let group = order.order.filter(singleorder=>singleorder.itemid===item.itemid)
    //Make sure no redundant record
    let repeated = balance.filter(singlenewbal=>singlenewbal===item.itemid)
    if ((group.length>0)&&(repeated.length===0)) {
      //If the item is found in the order and never recorder in newbalance
      let total = parseInt(item.inventory.total)
      group.forEach((grouporder)=>{
        if (subtract) {
          total -= parseInt(grouporder.qty)
        } else {
          total += parseInt(grouporder.qty)
        }
      })
      balance = [
        ...balance,
        {
          id: item.itemid,
          qty: total,
        },
      ]
    }
  })
  return balance 
}

//Batch inventory data update function
export const inventoryupdate = async (updateitemobj) => {
  const batch = writeBatch(db);
  if (updateitemobj.length > 0) { //Guarantee it is not an empty array
    updateitemobj.forEach(itemobj => { //Write the updated qty in batch
      batch.update(doc(db, "items", itemobj.id), {"inventory.total": itemobj.qty});
    });
    try {
      //Execute the batch update
      await batch.commit();
      return "Inventory total is updated"
    } catch (e) { //error handling
      return e
    }
  }
}

//Data delete function
export const deletedoc = async (col, docid) => {
  const delRef = doc(db, col, docid)
  try {
    await deleteDoc(delRef)
    return "Deleted"
  } catch (e) { //error handling
    return e
  }
}

//FILE deletion
export const deletefile = async (path) => {
  const deleteRef = ref(storage, path);
  try {
    await deleteObject(deleteRef)
    return true;
  } catch (e) {
    console.log(e)
    return false;
  }
}

//Date format function
export const dateconvert = (fbdate) => {
    let d = new Date(fbdate).getDate()
    let m = new Date(fbdate).getMonth()
    let y = new Date(fbdate).getFullYear()
    return (`${d}-${m+1}-${y}`)
}

//Time format function
export const timeconvert = (fbdate) => {
  let hh = new Date(fbdate).getHours()
  let mm = new Date(fbdate).getMinutes()
  let ss = new Date(fbdate).getSeconds()
  return (`${hh}:${mm}:${ss}`)
}

export const serverdateformat = (dmydate) => {
  const splitdate = dmydate.split("-");
  return new Date(splitdate[2], splitdate[1] - 1, splitdate[0]);
};

/*
//Read data from Firestore
export function datafetching(q, singledoc) {
  //It is the function to restreive data from FireStore and save it in a hook
  return new Promise((resolve, reject) => { //Return a promise of the update
    const reading = async () => {
      try { //Try to load the database from cache
        let querySnapshot
        if (singledoc) { querySnapshot = await getDoc(q, { source: 'cache' }) }
        else { querySnapshot = await getDocs(q, { source: 'cache' }) }
        console.log("From cache")
        //resolve (querySnapshot.data())
        console (querySnapshot)
        resolve (querySnapshot)
      }
      catch { //Failed to load the database from cache
          try { //Try to load the database from server
            let querySnapshot
            if (singledoc) { querySnapshot = await getDoc(q, { source: 'server' }) }
            else { querySnapshot = await getDocs(q, { source: 'server' }) }
            console.log("From server")
            //resolve (querySnapshot.data())
            console (querySnapshot)
            resolve (querySnapshot)
          }
          catch {
              console.log ("User loading FAILED!!!")
              reject ("User loading failed!")
          }
      }
    }
    reading() //Invoke the function
  })
}
*/