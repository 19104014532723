import React, { useState, useEffect } from 'react'
import { auth } from './initfirebase'
import { onAuthStateChanged } from "firebase/auth"

export const loginStatus = React.createContext()

const LoginProvider = (props) => {
  //Initialize the context value
  const [currentstatus, setCurrentstatus] = useState({
    status: false,
    user: '',
    uid: '',
    email: '',
    type: '',
    username: '',
    lang: 'zh',
  })
  useEffect(() => {
      onAuthStateChanged( auth, async loginuser => {
        localStorage.clear()
        //console.log(loginuser)
        if (loginuser) {
          setCurrentstatus({
            status: true,
            user: loginuser.displayName,
            uid: loginuser.uid,
            email: loginuser.email,
            type: '',
            username: '',
            lang: 'zh',
          })
        } else {
          setCurrentstatus({
            status: false,
            user: '',
            uid: '',
            email: '',
            type: '',
            username: '',
            lang: 'zh',
          })
        }
      })
  },[])

  return (
    <loginStatus.Provider value={{ currentstatus, setCurrentstatus }}>
      {props.children}
    </loginStatus.Provider>
  )
}

export default LoginProvider

