import React from "react";
import { makeStyles } from "@mui/styles";
import {
    Paper,
    Box,
    Typography,
    Stack,
  } from "@mui/material";
  import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
    container: {
      width: "100%",
      "@media (min-width:600px)": {
        width: "90%",
      },
      "@media (min-width:900px)": {
        width: "80%",
      },
      "@media (min-width:1200px)": {
        width: "60%",
      },
    },
    paperstyle: {
      padding: "16px",
      width: "30%",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      "@media (min-width:600px)": {
        padding: "32px",
        width: "150px",
        height: "150px",
      },
      '&:hover': {
        cursor: "pointer",
        background: "#50C878",
        color: "#ffffff",
      },
    },
  });

export default function POS() {
  const classes = useStyles();
  let navigate = useNavigate();

  return(
  <Box className={classes.container}>
      <Stack direction="column" alignItems="stretch" spacing={4}>
          <Typography paragraph variant="h1" align="center" sx={{ my: 4 }}>
              Retail Sales Input System
          </Typography>
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        spacing={8}
        sx={{pt: 8}}
      >
        <Paper onClick={()=>{navigate(`/pos/newsales`)}} className={classes.paperstyle} elevation={2} >
          <Typography variant="h1">
            New Sales
          </Typography>
        </Paper>
        <Paper onClick={()=>{navigate(`/pos/history`)}} className={classes.paperstyle} elevation={2}>
          <Typography variant="h1">
            History
          </Typography>
        </Paper>
      </Stack>
  </Box>
  )
}