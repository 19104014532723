import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { makeStyles } from "@mui/styles";
import { Typography, Stack, Button } from "@mui/material";
import { storage } from "../../firebase/initfirebase";
import { ref, uploadString, deleteObject, getDownloadURL } from "firebase/storage";
import { dataupdate } from "../../firebase/managedb";

const useStyles = makeStyles({
  uploadedimg: {
    width: "80%",
    height: "auto",
    "@media (min-width:600px)": {
      maxWidth: "400px",
      height: "auto",
    },
  },
  uploadbtn: {
    color: "#739c70",
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    border: "2px solid #739c70",
    width: "200px",
    "&:hover": {
      border: "3px solid #739c70",
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
  },
});

export default function Filehandle({fileinfo}) {
  const classes = useStyles();
  const {type, orderid, path, lang, otherfilearray, setOtherfilearray, order, setOrder} = fileinfo;

  //Hook for storing image
  const [uploadimgurl, setUploadimgurl] = useState({
    upload: false,
    path: '', //Server path => For delete the file in FB and generate the server url
    url: '', //Local: New Uploaded; Server: Stored in FB => For upload, display and download the file
  });

  //Initialization, run every time the file array changes -> load the image url if image file is stored
  useEffect(()=>{
    if (path) {
      const dlRef = ref(storage, path);
      getDownloadURL(dlRef)
      .then ((url)=>{
        setUploadimgurl({
            upload: false,
            path,
            url,
        });
      })
      .catch ((err)=>{
        console.log (err)
      })
    }
    // eslint-disable-next-line
  },[otherfilearray]);

  //Upload the image file
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    let upload = true;
    if (uploadimgurl.upload && uploadimgurl.path && uploadimgurl.url) {
      //console.log(uploadimgurl)
      const storageRef = ref(storage, uploadimgurl.path); //Where and what file name do you want to save
      uploadString(storageRef, uploadimgurl.url, "data_url")
        .then((snapshot) => {
          //Successfully uploaded
          //console.log(snapshot);
          //STORE the path to DB
          let fileobj;
          let newfilearray;
          if (type.substring(0,5)==="other") { //If it is other files, create the file array or add the new element at the end of the array
            newfilearray = !otherfilearray ? [uploadimgurl.path] : [...otherfilearray, uploadimgurl.path]
            fileobj = { otherfilespath: [...newfilearray]};
          } else {
            const filepath = `${type}filepath`;
            fileobj = { [filepath]: uploadimgurl.path };
          }
          dataupdate("orders", orderid, fileobj).then(
            (res) => {
              //console.log(`${res} image file is added!`);
              if (upload) {
                setUploadimgurl({ ...uploadimgurl, upload: false });
                setOrder({...order, ...fileobj});
                //If it is other files, update the file array hook in Orderdetail.js
                if (type.substring(0,5)==="other") { setOtherfilearray([...newfilearray]) };
              }
            },
            (err) => {
              console.log(err);
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (type==="input_po") {
      //The Customer PO upload in the Order Input Page
      sessionStorage.clear();
      if ( uploadimgurl.path && uploadimgurl.url) {
        //Write the url into session storage
        sessionStorage.setItem("uploadimgurl", JSON.stringify(uploadimgurl));
      }
    }
    return () => {
      upload = false;
    };
    // eslint-disable-next-line
  }, [uploadimgurl]);

  //Delete File
  const [deletefile, setDeletefile] = useState(false);
  useEffect(() => {
    let deleting = true;
    if (deletefile && uploadimgurl.path && uploadimgurl.url) {
      const deleteRef = ref(storage, uploadimgurl.path);
      // Delete the file
      deleteObject(deleteRef)
        .then(() => {
          // File deleted successfully
          //console.log("File deleted!");
          //Update the DB
          let fileobj;
          let newfilearray;
          if (type.substring(0,5)==="other") {
            //If it is other files, filter out the deleted element from the file array
            if (otherfilearray.length>1){
              newfilearray = otherfilearray.filter(otherfile => otherfile!==uploadimgurl.path)
              fileobj = { otherfilespath: [...newfilearray]};
            } else { 
              //If there is only one element in the file array, the file array will be empty
              newfilearray = ""
              fileobj = { otherfilespath: "" };
            }
          } else {
            const filepath = `${type}filepath`;
            fileobj = { [filepath]: "" };
          }
          dataupdate("orders", orderid, fileobj).then(
            (res) => {
              //console.log(`${res} Image file is deleted!`);
              if (deleting) {
                setDeletefile(false);
                setUploadimgurl({
                  upload: false,
                  path: "",
                  url: "",
                });
                setOrder({...order, ...fileobj});
                //If it is other files, update the file array hook in Orderdetail.js
                if (type.substring(0,5)==="other"){ setOtherfilearray( newfilearray ? [...newfilearray] : "") }
              }
            },
            (err) => {
              console.log(err);
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
    return () => {
      deleting = false;
    };
    // eslint-disable-next-line
  }, [deletefile]);

  //Upload the file
  const handleUpload = (event) => {
    //Upload button
    if (event.target.files[0].size <= 3000000) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(event.target.files[0]); //Read the (local machine) url of the file
      fileReader.onload = () => {
        if (type==="input_po") {
          //console.log(fileReader.result)
          //The Customer PO upload in the Order Input Page
          setUploadimgurl({
            upload: false,
            url: fileReader.result, //Local url
            path: event.target.files[0].name, //server path
          });
        } else {
          let extension = event.target.files[0].name.split(".");
          let finaltype
          if (type.substring(0,5)==="other") {
            const type2 = Date.now();
            finaltype = `other${type2}`;
          } else {
            finaltype = type;
          }
          setUploadimgurl({
            upload: true,
            url: fileReader.result, //Local url
            path: `orders/${orderid}_${finaltype}.${extension[extension.length - 1]}`, //server path
          }); //Finished upload and put the local url into the hook
          setUploading(false)
        }
      };
    } else {
      alert("上傳圖片不可超過 3 mb, 請重新上傳");
    }
  };

  //Delete the file
  const handleDelete = () => {
    //console.log("Deleting!!!")
    if (type==="input_po") {
      //The Customer PO upload in the Order Input Page
      //Reset the Uploadimgurl hook
      setUploadimgurl({
        upload: false,
        url: "",
        path: "",
      });
    } else {
      setDeletefile(true);
    }
  };

  //Download the file
  const handleDownload = () => {
    const link = document.createElement("a");
    const filename = uploadimgurl.path.split("_");
    link.download = filename[1];
    link.href = uploadimgurl.url;
    link.target = "_blank";
    link.click();
  };

  return (
    <React.Fragment>
      {(!uploadimgurl.url || !uploadimgurl.path) ? ( //Show the upload button
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <label htmlFor="contained-button-file">
            {
              uploading && //Guarantee the file selection popup is called by specific "type"
              <input
                hidden //Not using the default UI but use the Button below instead
                accept="image/*, application/pdf"
                id="contained-button-file"
                type="file"
                onChange={handleUpload}
              />
            }
            <Button
              variant="contained"
              className={classes.uploadbtn}
              component="span"
              startIcon={<UploadIcon fontSize="inherit" />}
              onClick={()=>setUploading(true)}
            >
              { lang==="en" ? "UPLOAD" : "上傳檔案" }
            </Button>
          </label>
        </Stack>
      ) : (uploadimgurl.upload || deletefile ? ( //Show the loading icon
        <Typography paragraph variant="h2" align="center" sx={{ my: 4 }}>
          <CircularProgress />
        </Typography>
      ) : (
        //Show the uploaded image
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            {uploadimgurl.path.split(".")[1].toLowerCase() !== "pdf" ? (
              <img
                src={uploadimgurl.url}
                alt=""
                className={classes.uploadedimg}
              />
            ) : (
              <InsertDriveFileIcon fontSize="large" />
            )}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="center"
              alignItems="center"
              spacing={4}
              sx={{ py: "16px" }}
            >
            {
              type!=="input_po" &&
              <Button
                variant="contained"
                className={classes.uploadbtn}
                component="span"
                startIcon={<DownloadIcon fontSize="inherit" />}
                onClick={handleDownload}
              >
                { lang==="en" ? "DOWNLOAD" : "下載檔案" }
              </Button>
            }
              <Button
                variant="contained"
                className={classes.uploadbtn}
                component="span"
                startIcon={<DeleteIcon fontSize="inherit" />}
                onClick={handleDelete}
              >
                { lang==="en" ? "DELETE" : "刪除檔案" }
              </Button>
            </Stack>
          </Stack>
      ))}
    </React.Fragment>
  );
}
