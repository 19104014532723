import React, {useState, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Box,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  IconButton,
  Select,
  } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
//import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from "react-router-dom"
import EditIcon from '@mui/icons-material/Edit';
import { db } from "../../firebase/initfirebase";
import { dateconvert, timeconvert } from "../../firebase/managedb"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const useStyles = makeStyles({
    container: {
      width: "100%",
      "@media (min-width:600px)": {
        width: "90%",
      },
      "@media (min-width:900px)": {
        width: "80%",
      },
      "@media (min-width:1200px)": {
        width: "60%",
      },
    },
    editbutton: {
      '&:hover': {
          cursor: "pointer",
          color: "#FF0000",
      },
    },
    diaglogoutlinedbtn: {
      color: "#739c70",
      borderRadius: "15px",
      backgroundColor: "#ffffff",
      boxShadow: "none",
      border: "2px solid #739c70",
      width: "200px",
      "&:hover": {
          border: "3px solid #739c70",
          backgroundColor: "#ffffff",
          boxShadow: "none",
      },
    },
    diaglogcontainedbtn: {
        color: "#ffffff",
        borderRadius: "15px",
        backgroundColor: "#739c70",
        boxShadow: "none",
        border: "2px solid #739c70",
        width: "130px",
        "&:hover": {
            backgroundColor: "#5c755a",
        },
    },
  });

const brands = ['QCHEFS', 'PHILOPET', 'BOGAR', 'BURBUR', 'SOPRA', 'MYCANI']
const itemid = ['qc-df', 'qc-dfc', 'qc-dm', 'qc-hc', 'qc-hz', 'qc-pz', 'qc-s', 'qc-sn', 'qc-cf', 'qc-cm', 'qc-cfc']
const bogaritemid = ['bo-c-c', 'bo-c-ml', 'bo-c-sm', 'bo-ch-c-c', 'bo-ch-c-d', 'bo-ch-f-c', 'bo-cl-con', 'bo-c1-sp', 'bo-cs-1', 'bo-cs-S', 'bo-derma', 'bo-ear', 'bo-f-c-m', 'bo-f-c-s', 'bo-so-c-m', 'bo-so-c-S', 'bo-so-1', 'bo-so-m', 'bo-so-s', 'bo-so-xl', 'bo-so-xs']

export default function History() {
  const classes = useStyles()
  let navigate = useNavigate()
  const [sales, setSales] = useState("ALL")
  const [brand, setBrand] = useState("ALL")
  const [popup, setPopup] = useState(false)
  const [edittr, setEdittr] = useState ()
  const [trlist, setTrlist] = useState()
  useEffect(()=>{
    let reading =true
    if (!trlist) {
      const readdb = async () => {
        let templist = []
        //Date Range
        var range = new Date("January 31, 2024 01:00:00")

        //Read the database orders
        let q = query(collection(db, "ps"), orderBy("timestamp", "desc"), where('timestamp', '>', range))
        try {
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((tr) => {
            tr.data().orders.forEach((order)=>{
              let tempdetail = {
                id: tr.id,
                payment: tr.data().payment,
                remarks: tr.data().remark,
                total: tr.data().total,
                timestamp: tr.data().timestamp.toDate(),
                brand: order.brand,
                itemid: order.itemid,
                name: order.name,
                price: order.price,
                qty: order.qty,
                subtotal: order.price*order.qty,
                sales: order.sales,
              }
              templist.push(tempdetail)
            })
          })
          if (reading) { 
            //console.log(templist)
            setTrlist(templist);
          }
        } catch (e) { //error handling
          console.log(e)
        }
      }
      readdb()
    }
    return () => { reading = false }
    // eslint-disable-next-line
  },[])

  const handleClick = (tr_id) => (e) => {
    //console.log(tr_id)
    //let tr = trlist.filter((tr)=>tr.id===tr_id)
    //console.log(tr)
    setEdittr(tr_id)
    setPopup(true)
  }

  const handleClose = (e) => {
    setEdittr("")
    setPopup(false)
  }

  const Datafilter = ({brand}) => {
    let filteredlist
    if (brand==="ALL") {
      sales==="ALL" ?
      filteredlist = trlist
      :
      filteredlist = trlist.filter((tr)=>tr.sales===sales)
    } else {
      sales==="ALL" ?
      filteredlist = trlist.filter((tr)=>tr.brand===brand)
      :
      filteredlist = trlist.filter((tr)=>tr.sales===sales && tr.brand===brand)
    }

  /*
    let qc_qty = []
    brand === "QCHEFS" &&
    itemid.forEach((item) => {
      let tempqty = 0
      filteredlist.filter((tr => tr.itemid===item)).forEach((tr) => {
        tempqty += parseInt(tr.qty)
      })
      qc_qty.push({
        itemid: item,
        totalqty: tempqty,
      })
    })

    let bo_qty = []
    brand === "BOGAR" &&
    bogaritemid.forEach((item) => {
      let tempqty = 0
      filteredlist.filter((tr => tr.itemid===item)).forEach((tr) => {
        tempqty += parseInt(tr.qty)
      })
      bo_qty.push({
        itemid: item,
        totalqty: tempqty,
      })
    })
    */

    let totalsales = 0
    let totalqty = 0
    filteredlist.forEach ((item)=>{
      totalsales += parseInt(item.subtotal)
      totalqty += parseInt(item.qty)
    })
    let payment = {
      cash: 0,
      credit: 0,
      octopus: 0,
      payme: 0,
      fps: 0,
      wechat: 0,
      alipay: 0,
    }
    filteredlist.forEach ((item)=>{
      if (item.payment==="cash") {
        let temppay = parseInt(payment.cash + item.subtotal)
        payment = {...payment, cash: temppay,}
      }
      if (item.payment==="credit") {
        let temppay = parseInt(payment.credit + item.subtotal)
        payment = {...payment, credit: temppay,}
      }
      if (item.payment==="octopus") {
        let temppay = parseInt(payment.octopus + item.subtotal)
        payment = {...payment, octopus: temppay,}
      }
      if (item.payment==="payme") {
        let temppay = parseInt(payment.payme + item.subtotal)
        payment = {...payment, payme: temppay,}
      }
      if (item.payment==="fps") {
        let temppay = parseInt(payment.fps + item.subtotal)
        payment = {...payment, fps: temppay,}
      }
      if (item.payment==="wechat") {
        let temppay = parseInt(payment.wechat + item.subtotal)
        payment = {...payment, wechat: temppay,}
      }
      if (item.payment==="alipay") {
        let temppay = parseInt(payment.alipay + item.subtotal)
        payment = {...payment, alipay: temppay,}
      }
    })  
    return(
      <TableBody>
        {
          /*
          brand === "QCHEFS" &&
          qc_qty.map((qty, index) => (
            <TableRow key={index}>
              <TableCell sx={{color: "#000000"}}>{qty.itemid}</TableCell> 
              <TableCell
                style={{ color: "#000000" }}
                colSpan={8}
              >
                {qty.totalqty}
              </TableCell>
            </TableRow>
          ))
          */
        }
        {
          /*
          brand === "BOGAR" &&
          bo_qty.map((qty, index) => (
            <TableRow key={index}>
              <TableCell sx={{color: "#000000"}}>{qty.itemid}</TableCell> 
              <TableCell
                style={{ color: "#000000" }}
                colSpan={8}
              >
                {qty.totalqty}
              </TableCell>
            </TableRow>
          ))
          */
        }
      {      
        filteredlist.map((tr, index) => (
          <TableRow
          key={index} 
          hover
          onClick={handleClick(tr.id)}
          sx={{ "&:hover": { cursor: "pointer" } }}
          >
              <TableCell align="center" sx={{color: "#000000"}}>{`${dateconvert(tr.timestamp)}`}</TableCell> 
              <TableCell align="center" sx={{color: "#000000"}}>{`${timeconvert(tr.timestamp)}`}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{tr.sales}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{tr.brand}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{tr.name}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{tr.price}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{tr.qty}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{tr.payment}</TableCell>
              <TableCell align="center" sx={{color: "#000000"}}>{`$${tr.subtotal}`}</TableCell>
          </TableRow>
        ))
      }
        <TableRow >
            <TableCell align="center" sx={{color: "#000000"}}>{`Cash: ${payment.cash}`}</TableCell> 
            <TableCell align="center" sx={{color: "#000000"}}>{`Credit card: ${payment.credit}`}</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>{`Octopus: ${payment.octopus}`}</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>{`PayMe: ${payment.payme}`}</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>{`FPS: ${payment.fps}`}</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>{`WeChat: ${payment.wechat}`}</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>{`AliPay: ${payment.alipay}`}</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>/</TableCell>
            <TableCell align="center" sx={{color: "#000000"}}>/</TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ color: "#000000" }}
            colSpan={9}
          >
            Total: ${parseInt(totalsales)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ color: "#000000" }}
            colSpan={9}
          >
            Total Qty: {parseInt(totalqty)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ color: "#000000" }}
            colSpan={9}
          >
            &nbsp;
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  return(
  <Box className={classes.container}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
          <Box sx={{ my: "16px" }}>
              <FormControl className={classes.sfield}>
                  <InputLabel id="sales">Sales</InputLabel>
                  <Select
                      labelId="Sales-label"
                      id="Sales"
                      value={sales}
                      label="Sales"
                      onChange={(e) => setSales(e.target.value)}
                  >
                      <MenuItem value={"ALL"}>
                          ALL
                      </MenuItem>
                      <MenuItem value={"Doris Tse"}>
                          Doris Tse
                      </MenuItem>
                      <MenuItem value={"Stephanie"}>
                          Stephanie
                      </MenuItem>
                      <MenuItem value={"Tung"}>
                          Tung
                      </MenuItem>
                      <MenuItem value={"Kay"}>
                          Kay
                      </MenuItem>
                      <MenuItem value={"Vicky"}>
                          Vicky
                      </MenuItem>
                  </Select>
              </FormControl>
          </Box>
          <Box sx={{ my: "16px" }}>
              <FormControl className={classes.sfield}>
                  <InputLabel id="brand">Brand</InputLabel>
                  <Select
                      labelId="Brand-label"
                      id="Brand"
                      value={brand}
                      label="Brand"
                      onChange={(e) => setBrand(e.target.value)}
                  >
                    <MenuItem value={"ALL"}>
                        ALL
                    </MenuItem>
                    {
                      brands.map((brand, index) => (
                        <MenuItem key={index} value={brand}>
                          {brand}
                        </MenuItem>
                      ))
                    }
                  </Select>
              </FormControl>
          </Box>
        </Stack>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{color: "#000000"}}>Date</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Time</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Sales</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Brand</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Item</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Price</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Qty</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>Payment</TableCell>
                        <TableCell align="center" sx={{color: "#000000"}}>SubTotal</TableCell>
                    </TableRow>
                </TableHead>
                {
                  trlist &&
                  /*
                  brands.map((brand, index)=>(
                    <Datafilter
                    key={index}
                    brand={brand}
                    />
                  ))
                  */
                  <Datafilter
                  //brand={"ALL"}
                  brand={brand}
                  />
                }
            </Table>
        </TableContainer>
      </Stack>
      
      <Dialog open={popup} onClose={handleClose}>
        <DialogTitle>
            {edittr}
            {
              /*
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 16,
                        top: 16,
                        color: "#000000",
                    }}
                >
                    <CloseIcon />
                </IconButton>
              */
            }
        </DialogTitle>
              {
                /*
              <DialogContent>
                  <Stack spacing={4} sx={{ my: "16px" }}>
                <FormControl className={classes.sfield}>
                    <InputLabel id="sales">Sales</InputLabel>
                    <Select
                        labelId="Sales-label"
                        id="Sales"
                        value={edittr[0].sales}
                        label="Sales"
                        onChange={(e)=>setEdittr({
                          ...edittr,
                          sales: e.value,
                        })}
                    >
                        <MenuItem value={"Ivan Lai"}>
                            Ivan Lai
                        </MenuItem>
                        <MenuItem value={"Doris Tse"}>
                            Doris Tse
                        </MenuItem>
                        <MenuItem value={"Stephanie"}>
                            Stephanie
                        </MenuItem>
                        <MenuItem value={"Tung"}>
                            Tung
                        </MenuItem>
                        <MenuItem value={"Vicky"}>
                            Vicky
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    className={classes.sfield}
                    id="qty"
                    label="Quantity"
                    placeholder="Quantity"
                    type="number"
                    variant="outlined"
                    value={order.qty}
                    onChange={handleDGChange("qty")}
                />
                <TextField
                    className={classes.sfield}
                    id="price"
                    label="Price"
                    placeholder="Price"
                    type="number"
                    variant="outlined"
                    value={order.price}
                    onChange={handleDGChange("price")}
                />
                  </Stack>
              </DialogContent>
                */
              }
        <DialogActions sx={{ px: 3, pb: 3, justifyContent: "center" }}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                spacing={{ xs: 2, sm: 4 }}
            >
                <Button
                    className={classes.diaglogcontainedbtn}
                    onClick={handleClose}
                >
                    OK
                </Button>
                {
                  /*
                    <Button
                        className={classes.diaglogcontainedbtn}
                        onClick={handleDGConfirm}
                        disabled={
                            !order.qty || !order.price
                                ? true
                                : false
                        }
                    >
                        CONFIRM
                    </Button>
                  */
                }
            </Stack>
        </DialogActions>
    </Dialog>
  </Box>
  )
}