import { auth } from './initfirebase'
import { signInWithEmailAndPassword, signOut } from "firebase/auth"

/*
export function createuserprofile(user) { // Handle the Sign out
    return new Promise((resolve, reject) => { //Create the promise
        fs.collection('Students').doc(user.uid).set({ //Create a user profile in Firestore
            'name': user.displayName,
            'email': user.email,
            'finished_course': [],
            'registered_course': [],
            'topup_record': [],
        })
        .then((result) => {
            resolve(result) //Return the resolve function if login successfully
        }) 
        .catch(error => {
            auth.currentUser.delete() //Delete the created user
            reject(error.message)  //Return the error message if login failed
        })
    })
}*/

export function signout() { // Handle the Sign out
    signOut(auth).then((result) => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
        console.log(error.message)
    });
}

export function signin(email, password) {
    return new Promise((resolve, reject) => { //Create the promise
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user
            resolve(user)
        })
        .catch(error => reject(error.message))
    })
}
