import React, { useState, useEffect } from "react";
import { dataupdate, deletedoc, inventorycalculate, inventoryupdate, deletefile } from "../../firebase/managedb";
import { useNavigate } from "react-router-dom"
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker, PickersDay } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Divider,
  IconButton,
  Checkbox,
  Select,
  TextField,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { channellist, saleslist, orderstatuslist, paystatuslist, paymentlist, deliverylist } from "../listsnobjects";

const useStyles = makeStyles({
  container: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "90%",
    },
    "@media (min-width:900px)": {
      width: "80%",
    },
    "@media (min-width:1200px)": {
      width: "60%",
    },
  },
  paperstyle: {
    padding: "16px",
    "@media (min-width:600px)": {
      padding: "32px",
    },
  },
  sfield: {
    width: "200px",
  },
  mfield: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "300px",
    },
  },
  lfield: {
    width: "100%",
    "@media (min-width:600px)": {
      width: "500px",
    },
  },
  fullfield: {
    width: "100%",
  },
  diaglogoutlinedbtn: {
    color: "#739c70",
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    boxShadow: "none",
    border: "2px solid #739c70",
    width: "200px",
    "&:hover": {
      border: "3px solid #739c70",
      backgroundColor: "#ffffff",
      boxShadow: "none",
    },
  },
  diaglogcontainedbtn: {
    color: "#ffffff",
    borderRadius: "15px",
    backgroundColor: "#739c70",
    boxShadow: "none",
    border: "2px solid #739c70",
    width: "200px",
    "&:hover": {
      backgroundColor: "#5c755a",
    },
  },
});

export default function Dialogbox({ props }) {
  const classes = useStyles();
  let navigate = useNavigate();
  const { section, dialogswitch, setDialogswitch, order, setOrder, itemlist, lang } = props;

  //Hook for the orderstatus section
  const [editorderstatus, setEditorderstatus] = useState({
    orderstatus: order.orderstatus,
    orderdate: order.orderdate,
    customerpo: order.customerpo,
    channels: order.channels,
    sales: order.sales,
    invoice: order.invoice,
    dn: order.dn,
  });

  //Hook for the paymentstatus section
  const [editpaymentstatus, setEditpaymentstatus] = useState({
    paymentstatus: order.paymentstatus,
    orderpayment: order.orderpayment,
    paiddate: order.paiddate,
    paymentduedate: order.paymentduedate,
  });

  //Hook for the samples section
  const [editsamples, setSamples] = useState({
    samples: order.samples,
    flyers: order.flyers,
    instructioncard: order.instructioncard,
    posters: order.posters,
    props: order.props,
    promocard: order.promocard,
  });

  //Hook for the deliverystatus section
  const [editdeliverystatus, setEditdeliverystatus] = useState({
    deliverymethod: order.deliverymethod,
    deliverydate: order.deliverydate ? order.deliverydate : new Date(),
    remarks: order.remarks,
  });

  //Hook for the customerinformation section
  const [editcustomerinformation, setEditcustomerinformation] = useState({
    type: order.type,
    name: order.name,
    contact: order.contact,
    phone: order.phone,
    email: order.email,
    address: order.address,
    address2: order.address2,
    remarks: order.ctrremarks,
  });

  //Hook for the update the customer profile
  const [updatectr, setUpdatectr] = useState(false);

  //Hook for the orderitems section confirm button
  const [disableitembtn, setDisableitembtn] = useState(true);

  //Hook for items selection list
  const initial_item_list_open = new Array(order.order.length).fill(false);
  const [itemlistopen, setItemlistopen] = useState([...initial_item_list_open]); //The Item Selection List

  //Hook for the orderitems and additional section
  const [editorderitems, setEditorderitems] = useState(order.order);
  useEffect(() => {
    //console.log(editorderitems)
    //Validation
    if (editorderitems) {
      let noerror = true;
      for (let i = 0; i < editorderitems.length; i++) {
        if (!editorderitems[i].itemid) {
          noerror = false;
          //console.log(`${editorderitems[i].itemid}, ItemID issue`);
        } else if (!editorderitems[i].qty || isNaN(editorderitems[i].qty)) {
          noerror = false;
          //console.log(`${editorderitems[i].qty}, quantity issue`);
        } else if (isNaN(editorderitems[i].price)) {
          noerror = false;
          //console.log(`${editorderitems[i].price}, price issue`);
        }
      }
      //Valid -> noerror=true && disableitembtn=false / Invalid -> noerror=false && disableitembtn=true
      if (noerror===disableitembtn) {
        setDisableitembtn(!noerror);
      }
    }
    // eslint-disable-next-line
  }, [editorderitems]);

  //Hook for the orderitems and additional section
  const [edittotal, setEdittotal] = useState(order.total);

  //Update to the database
  const [updatedb, setUpdatedb] = useState(false);
  useEffect(() => {
    let updating = true;

    //Updating
    if (updatedb) {
      let updateobj;
      let newbalance;
      if (section === "orderstatus") {
        updateobj = editorderstatus;
        //console.log(order.orderstatus)
        //console.log(editorderstatus.orderstatus)
        if ((order.orderstatus!=="delivered")||(order.orderstatus!=="finished")){
          if ((editorderstatus.orderstatus==="delivered")||(editorderstatus.orderstatus==="finished")) {
            newbalance = inventorycalculate(itemlist, order, true);
            //newbalance = inventorycalculate();
            //console.log(newbalance);
          }
        }
      } else if (section === "paymentstatus") {
        updateobj = editpaymentstatus;
      } else if (section === "samples") {
        updateobj = editsamples;
      } else if (section === "deliverystatus") {
        updateobj = editdeliverystatus;
      } else if (section === "customerinformation") {
        const {remarks, ...otherprops} = editcustomerinformation;
        updateobj = {...otherprops, ctrremarks: remarks,};
      } else if (section === "orderitems") {
        const templist = editorderitems.map((order)=>{
          delete order.name
          return order
        })
        updateobj = {
          order: [...templist],
          total: edittotal,
        };
      } else if (section === "delivereddialog") {
        updateobj = {
          ...editdeliverystatus,
          orderstatus: "delivered",
        };
        newbalance = inventorycalculate(itemlist, order, true);
        //newbalance = inventorycalculate();
        //console.log(newbalance)
      }
      //Update the order info to the database
      dataupdate("orders", order.orderid, updateobj).then(
        (res) => {
          //console.log(`${res} This single order!`);
          //Update the customer profile to the database
          if (updatectr) {
            dataupdate("customers", order.customerid, section !== "paymentstatus" ? editcustomerinformation : { orderpayment: updateobj.orderpayment }).then(
              (res) => {
                //console.log(`${res} the customer!`);
              },
              (err) => {
                console.log(err);
              }
            );
          }
          //Update the inventory to the database item
          if (newbalance) {
            //console.log(newbalance)
            inventoryupdate(newbalance).then(
              (res) => {console.log(res);},
              (err) => {console.log(err);}
            )
          }
        },
        (err) => {
          console.log(err);
        }
      );
      if (updating) {
        //console.log("Refresh the page");
        setUpdatedb(false);
        setOrder({ ...order, ...updateobj });
        setDialogswitch({ ...dialogswitch, [section]: false });
      }
      return () => {
        updating = false;
      };
    }
    // eslint-disable-next-line
  }, [updatedb]);

  //Delete the order
  const [deldoc, setDeldoc] = useState(false);
  useEffect(() =>{
    let deleting = true
    if ((deldoc) && (section === "deleteorder")) {
      const id = order.orderid;
      //Add the goods quantity on the inventory balance if the goods has been "delivered"
      if ((order.orderstatus==="delivered")||(order.orderstatus==="finished")){
        let newbalance = inventorycalculate(itemlist, order, false);
        if (newbalance) {
          //console.log(newbalance)
          inventoryupdate(newbalance).then(
            (res) => {
              //console.log(res);
            },
            (err) => {
              console.log(err);
            }
          )
        }
      };
      deletedoc("orders", id).then(
        (res) => {
          //console.log(`${res} order ${id}!`);
          //Delete all files related to the order
          let filesdeletestatus = [...order.otherfilespath]
          if (order.customerpofilepath) {
            filesdeletestatus.push(order.customerpofilepath)
          }
          if (order.paymentfilepath) {
            filesdeletestatus.push(order.paymentfilepath)
          }
          if (order.courierfilepath) {
            filesdeletestatus.push(order.courierfilepath)
          }
          if (order.invoicefilepath) {
            filesdeletestatus.push(order.invoicefilepath)
          }
          if (order.dnfilepath) {
            filesdeletestatus.push(order.dnfilepath)
          }
          let allfilesclear = filesdeletestatus.map((filepath)=>{
            return deletefile(filepath)
          })
          //console.log(filesdeletestatus);
          if (allfilesclear.findIndex(element => element === false) < 0) {
            if (deleting) {
              //console.log("Deleted all files!");
              setDeldoc(false);
              setDialogswitch({ ...dialogswitch, [section]: false });
              navigate('/orderlist');
            };
          }
        },
        (err) => {
          console.log(err);
        }
      );
      return () => {
        deleting = false;
      };
    }
  // eslint-disable-next-line
  },[deldoc])

  //---------Event Handler--------------

  //Common handler
  const handleClose = () => {
    setDialogswitch({ ...dialogswitch, [section]: false });
  };

  const handleChange = (prop) => (e) => {
    if (section === "orderstatus") {
      setEditorderstatus({
        ...editorderstatus,
        [prop]: prop === "orderdate" ? e : e.target.value,
      });
    } else if (section === "paymentstatus") {
      setEditpaymentstatus({
        ...editpaymentstatus,
        [prop]: ((prop!=="paiddate") && (prop!=="paymentduedate")) ? e.target.value : e,
      });
    } else if (section === "samples") {
      setSamples({
        ...editsamples,
        [prop]: e.target.value,
      });
    } else if ((section === "deliverystatus")||(section === "delivereddialog")) {
      setEditdeliverystatus({
        ...editdeliverystatus,
        [prop]: prop === "deliverydate" ? e : e.target.value,
      });
    } else if (section === "customerinformation") {
      setEditcustomerinformation({
        ...editcustomerinformation,
        [prop]: e.target.value,
      });
    }
  };

  const handleConfirm = () => {
    let chkobj;
    if (section === "orderstatus") {
      chkobj = {
        ...editorderstatus,
        orderstatus: editorderstatus.orderstatus
          ? editorderstatus.orderstatus
          : order.orderstatus,
        orderdate: editorderstatus.orderdate
          ? editorderstatus.orderdate
          : order.orderdate,
        channels: editorderstatus.channels
          ? editorderstatus.channels
          : order.channels,
        sales:
          editorderstatus.channels === "sales"
            ? editorderstatus.sales
              ? editorderstatus.sales
              : order.sales
            : "",
      };
      setEditorderstatus(chkobj);
    } else if (section === "paymentstatus") {
      chkobj = {
        ...editpaymentstatus,
        paymentstatus: editpaymentstatus.paymentstatus
          ? editpaymentstatus.paymentstatus
          : order.paymentstatus,
        orderpayment: editpaymentstatus.orderpayment
          ? editpaymentstatus.orderpayment
          : order.orderpayment,
        paymentduedate: editpaymentstatus.paymentduedate
          ? editpaymentstatus.paymentduedate
          : (order.paymentduedate ? order.paymentduedate : new Date()),
        paiddate: 
        editpaymentstatus.paymentstatus === "paid" ?
        (
          editpaymentstatus.paiddate ? editpaymentstatus.paiddate : (order.paiddate ? order.paiddate : new Date())
        )
        : "",
      };
      setEditpaymentstatus(chkobj);
    } else if ((section === "deliverystatus") || (section === "delivereddialog")) {
      chkobj = {
        ...editdeliverystatus,
        deliverymethod: editdeliverystatus.deliverymethod
          ? editdeliverystatus.deliverymethod
          : order.deliverymethod,
        deliverydate: editdeliverystatus.deliverydate ? editdeliverystatus.deliverydate : order.deliverydate,
      };
      setEditdeliverystatus(chkobj);
    } else if (section === "customerinformation") {
      //console.log(editcustomerinformation)
      chkobj = {
        ...editcustomerinformation,
        type: editcustomerinformation.type
          ? editcustomerinformation.type
          : order.type,
        name: editcustomerinformation.name,
      };
      setEditcustomerinformation(chkobj);
    } else if (section === "orderitems") {
      //If price has no input, 0 will be assigned
      chkobj = editorderitems.map((obj)=>
        obj.price ? obj : {...obj, price: 0,}
      );
      setEditorderitems(chkobj);
    };
    //Samples does not need object checking
    setUpdatedb(true);
  };

  //Customer Information
  const handleCtrCheck = (e) => {
    setUpdatectr(e.target.checked);
  };

  //Order Items
  //Store the order in the array
  const itemhandleChange = (prop) => (event, value) => {
    let temparrayobj = [...editorderitems]; //Extract the data from hook orders and store in a variable neworderobj
    let neworderobj = {...temparrayobj[prop.index]}; //Extract the current array object
    let inputvalue = event.target.value;

    if (prop.input === "itemid") {
      //In case change the item
      if (value) {
        //Extract the price from database
        let price =
          order.type === "Business"
            ? itemlist.filter((item) => item.itemid === value.itemid)[0].dsp
            : itemlist.filter((item) => item.itemid === value.itemid)[0].rsp;
        //Extract the description from database
        let description = itemlist.filter((item) => item.itemid === value.itemid)[0].description;
        temparrayobj[prop.index] = { // Put the value into the object property
          itemid: value.itemid,
          name: value.name,
          qty: 1, //default value
          price,
          description,
        }; 
      } else {
        temparrayobj[prop.index] = { // Put the value into the object property
          itemid: "",
          name: "",
          qty: "", 
          price: "",
          description: "",
        }; 
      }
      //temparrayobj[prop.index] = {...neworderobj}; // Put the updated object back to the array
    } else if (prop.input === "qty") {
      //In case change the qty
      if (inputvalue) {
        inputvalue = parseInt(event.target.value) >= 1 ? parseInt(event.target.value) : 1;
      }
      neworderobj.qty = inputvalue; // Put the value into the object property
      temparrayobj[prop.index] = neworderobj; // Put the updated object back to the array
    } else if (prop.input === "price") {
      //In case change the price
      let inputvalue = event.target.value;
      if (inputvalue) {
        if (parseFloat(inputvalue) < 0) {
          // NO -xxx
          inputvalue = 0;
        } else {
          //Convert to string
          let text = parseFloat(event.target.value).toFixed(2).toString();
          if (text.charAt(text.length - 1) === "0") {
            if (text.charAt(text.length - 2) === "0") {
              // xxx.00
              inputvalue = parseInt(event.target.value);
            } else {
              // xxx.x0
              inputvalue = parseFloat(event.target.value).toFixed(1);
            }
          } else {
            // xxx.xx
            inputvalue = parseFloat(event.target.value).toFixed(2);
          }
        }
      }
      neworderobj.price = inputvalue; // Put the value into the object property
      temparrayobj[prop.index] = neworderobj; // Put the updated object back to the array
    } else {
      //In case change description
      neworderobj.description = inputvalue; // Put the value into the object property
      temparrayobj[prop.index] = neworderobj; // Put the updated object back to the array
    }
    //Update the total amount
    let total = 0;
    temparrayobj.forEach((order) => {
      if (order.price > 0 && order.qty > 0) {
        total = parseFloat(
          parseFloat(order.price) * parseFloat(order.qty) + parseFloat(total)
        ).toFixed(2);
      }
    });
    //console.log(temparrayobj)
    setEdittotal(total);
    setEditorderitems(temparrayobj); //Update the hook
  };

  //delete the row from the order array
  const deleteitem = (prop) => () => {
    //Extract the data from hook orders and store in a variable neworderobj
    let temparrayobj = [...editorderitems];
    if (prop === 0 && temparrayobj.length === 1) {
      //In case only 1 item row to be deleted, put the empty object
      let blankobj = { //Object
        itemid: "",
        name: "",
        qty: "",
        price: "",
        description: "",
      };
      temparrayobj.splice(prop, 1, blankobj);
    } else {
      temparrayobj.splice(prop, 1); //In case delete any 1 of item row
    }
    //Update the total amount
    let total = 0;
    temparrayobj.forEach((order) => {
      if (order.price > 0 && order.qty > 0) {
        total = parseFloat( parseFloat(order.price) * parseFloat(order.qty) + parseFloat(total) ).toFixed(2);
      }
    });
    setEdittotal(total);
    setEditorderitems(temparrayobj); //Update the hook

    //The Item Selection List
    if (itemlistopen.length===1) {
      setItemlistopen([false]);
    } else {
      let tempopenlist = [...itemlistopen];
      tempopenlist.splice(prop, 1);
      setItemlistopen([...tempopenlist]);
    }

  };

  //Add 1 row from the order array
  const additem = () => {
    //Add the blank object
    let neworderobj = {
        //Object
        itemid: "",
        name: "",
        qty: "",
        price: "",
        description: "",
      };
    setEditorderitems( editorderitems => [...editorderitems, neworderobj] );

    //The Item Selection List
    let tempopenlist = [...itemlistopen]
    tempopenlist.push(false)
    setItemlistopen([...tempopenlist])
  };

  return (
    <Box>
      {section === "orderstatus" && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogTitle>
            Order Status
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "#000000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ my: "16px" }}>
              <FormControl className={classes.sfield}>
                <InputLabel id="orderstatus-label">Order Status</InputLabel>
                <Select
                  labelId="Orderstatus-label"
                  id="Orderstatus"
                  value={editorderstatus.orderstatus}
                  label="Order Status"
                  onChange={handleChange("orderstatus")}
                >
                  {orderstatuslist.map((orderstatus) => (
                    <MenuItem key={orderstatus.id} value={orderstatus.id}>
                      {orderstatus.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Order Date"
                  value={editorderstatus.orderdate}
                  onChange={handleChange("orderdate")}
                  renderInput={(params) => (
                    <TextField {...params} className={classes.mfield} />
                  )} //Display the input field
                  //Display the calendar
                  renderDay={(days, selected, pickersDayProps) => (
                    <PickersDay
                      //Display DAY in the calendar
                      {...pickersDayProps}
                      sx={{ color: "#000000" }}
                    />
                  )}
                  okText={<span style={{ color: "#000000" }}>CONFIRM</span>}
                  cancelText={<span style={{ color: "#000000" }}>CANCEL</span>}
                />
              </LocalizationProvider>
              <TextField
                className={classes.fullfield}
                id="CustomerPO"
                label="Customer PO / Ref. No."
                placeholder="Customer PO / Ref. No."
                variant="outlined"
                value={editorderstatus.customerpo}
                onChange={handleChange("customerpo")}
              />
              <FormControl className={classes.mfield}>
                <InputLabel id="channel-label">Channel</InputLabel>
                <Select
                  labelId="channel-label"
                  id="channel"
                  value={editorderstatus.channels}
                  label="Channel"
                  onChange={handleChange("channels")}
                >
                  {channellist.map((channel) => (
                    <MenuItem key={channel.id} value={channel.id}>
                      {channel.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                className={classes.mfield}
                disabled={editorderstatus.channels !== "sales" ? true : false}
              >
                <InputLabel id="sales-label">Sales Person</InputLabel>
                <Select
                  labelId="sales-label"
                  id="sales"
                  value={
                    editorderstatus.channels === "sales" &&
                    editorderstatus.sales
                      ? editorderstatus.sales
                      : ""
                  }
                  label="Sales Person"
                  onChange={handleChange("sales")}
                >
                  {editorderstatus.channels !== "sales" ? (
                    <MenuItem value={""}></MenuItem>
                  ) : (
                    saleslist.map((sales) => (
                      <MenuItem key={sales} value={sales}>
                        {sales}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <TextField
                className={classes.fullfield}
                id="invoice"
                label="Invoice Number"
                placeholder="Invoice Number"
                variant="outlined"
                value={editorderstatus.invoice}
                onChange={handleChange("invoice")}
              />
              <TextField
                className={classes.fullfield}
                id="dn"
                label="DN Number"
                placeholder="DN Number"
                variant="outlined"
                value={editorderstatus.dn}
                onChange={handleChange("dn")}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleConfirm}
                disabled={
                  editorderstatus.channels === "sales" && !editorderstatus.sales
                    ? true
                    : false
                }
              >
                CONFIRM
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {section === "paymentstatus" && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogTitle>
            Payment Status
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "#000000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ my: "16px" }}>
              <FormControl className={classes.sfield}>
                <InputLabel id="paymentstatus-label">Payment Status</InputLabel>
                <Select
                  labelId="Paymentstatus-label"
                  id="Paymentstatus"
                  value={editpaymentstatus.paymentstatus}
                  label="Payment Status"
                  onChange={handleChange("paymentstatus")}
                >
                  {paystatuslist.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.mfield}>
                <InputLabel id="orderpayment-label">Payment Term</InputLabel>
                <Select
                  labelId="orderpayment-label"
                  id="orderpayment"
                  value={editpaymentstatus.orderpayment}
                  label="Payment Term"
                  onChange={handleChange("orderpayment")}
                >
                  {paymentlist.map((payment) => (
                    <MenuItem key={payment.id} value={payment.id}>
                      {payment.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                className={classes.fullfield}
                label="Update the Customer Profile"
                control={
                  <Checkbox
                    checked={updatectr}
                    onChange={handleCtrCheck}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label= {editpaymentstatus.paymentstatus==="paid" ? "Paid Date" : "Payment Due"}
                  value={editpaymentstatus.paymentstatus==="paid" ? 
                  (editpaymentstatus.paiddate ? editpaymentstatus.paiddate : new Date())
                  : 
                  (editpaymentstatus.paymentduedate ? editpaymentstatus.paymentduedate : new Date()) }
                  onChange={handleChange(editpaymentstatus.paymentstatus==="paid" ? "paiddate" : "paymentduedate")}
                  renderInput={(params) => (
                    <TextField {...params} className={classes.mfield} />
                  )} //Display the input field
                  //Display the calendar
                  renderDay={(days, selected, pickersDayProps) => (
                    <PickersDay
                      //Display DAY in the calendar
                      {...pickersDayProps}
                      sx={{ color: "#000000" }}
                    />
                  )}
                  okText={<span style={{ color: "#000000" }}>CONFIRM</span>}
                  cancelText={<span style={{ color: "#000000" }}>CANCEL</span>}
                />
              </LocalizationProvider>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleConfirm}
              >
                CONFIRM
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {section === "samples" && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogTitle>
            Samples & Marketing Materials
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "#000000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ my: "16px" }}>
              <TextField
                className={classes.fullfield}
                id="samples"
                label="Samples"
                placeholder="Samples"
                variant="outlined"
                multiline
                value={editsamples.samples}
                onChange={handleChange("samples")}
              />
              <TextField
                className={classes.mfield}
                id="flyersqty"
                label="Flyers"
                placeholder="Flyers"
                type="number"
                variant="outlined"
                value={editsamples.flyers}
                onChange={handleChange("flyers")}
              />
              <TextField
                className={classes.mfield}
                id="Instructions Card"
                label="Instructions Card"
                placeholder="Instructions Card"
                type="number"
                variant="outlined"
                value={editsamples.instructioncard}
                onChange={handleChange("instructioncard")}
              />
              <TextField
                className={classes.mfield}
                id="Promotion Card"
                label="Promotion Card"
                placeholder="Promotion Card"
                type="number"
                variant="outlined"
                value={editsamples.promocard}
                onChange={handleChange("promocard")}
              />
              <TextField
                className={classes.mfield}
                id="Poster"
                label="Poster"
                placeholder="Poster"
                type="number"
                variant="outlined"
                value={editsamples.posters}
                onChange={handleChange("posters")}
              />
              <TextField
                className={classes.mfield}
                id="Props"
                label="Props"
                placeholder="Props"
                type="number"
                variant="outlined"
                value={editsamples.props}
                onChange={handleChange("props")}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleConfirm}
              >
                CONFIRM
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {(section === "deliverystatus" || section === "delivereddialog") && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogTitle>
            { lang==="en" ? "Delivery Status" : "送貨狀態" }
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "#000000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ my: "16px" }}>
              <FormControl className={classes.sfield}>
                <InputLabel id="Delivery-label">
                  { lang==="en" ? "Delivery Method" : "送貨方法" }
                </InputLabel>
                <Select
                  labelId="Delivery-label"
                  id="deliverymethod"
                  value={editdeliverystatus.deliverymethod}
                  label= { lang==="en" ? "Delivery Method" : "送貨方法" }
                  onChange={handleChange("deliverymethod")}
                >
                  {deliverylist.map((delivery) => (
                    <MenuItem key={delivery.id} value={delivery.id}>
                      { lang==="en" ? delivery.display : delivery.cdisplay }
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label= { lang==="en" ? "Delivery Date" : "送貨日期" } 
                  value={editdeliverystatus.deliverydate}
                  onChange={handleChange("deliverydate")}
                  renderInput={(params) => (
                    <TextField {...params} className={classes.mfield} />
                  )} //Display the input field
                  //Display the calendar
                  renderDay={(days, selected, pickersDayProps) => (
                    <PickersDay
                      //Display DAY in the calendar
                      {...pickersDayProps}
                      sx={{ color: "#000000" }}
                    />
                  )}
                  okText={<span style={{ color: "#000000" }}>{ lang==="en" ? "CONFIRM" : "確認" }</span>}
                  cancelText={<span style={{ color: "#000000" }}>{ lang==="en" ? "CANCEL" : "取消" }</span>}
                />
              </LocalizationProvider>
              <TextField
                className={classes.fullfield}
                id="remarks"
                label={ lang==="en" ? "Remarks" : "備註" }
                placeholder={ lang==="en" ? "Remarks" : "備註" }
                variant="outlined"
                multiline
                value={editdeliverystatus.remarks}
                onChange={handleChange("remarks")}
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleClose}
              >
                { lang==="en" ? "CANCEL" : "取消" }
              </Button>
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleConfirm}
              >
                { lang==="en" ? "CONFIRM" : "確認" }
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {section === "customerinformation" && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogTitle>
            Customer Information
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "#000000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ my: "16px" }}>
              <FormControl className={classes.mfield}>
                <InputLabel id="customertype-label">Customer Type</InputLabel>
                <Select
                  labelId="customertype-label"
                  id="customertype"
                  value={editcustomerinformation.type}
                  label="Customer Type"
                  onChange={handleChange("type")}
                >
                  <MenuItem value="Business">Business</MenuItem>
                  <MenuItem value="User">User</MenuItem>
                </Select>
              </FormControl>
              {editcustomerinformation.type === "Business" && ( //Show company input field for Business type only
                <TextField
                  className={classes.lfield}
                  id="name"
                  label="Company name"
                  variant="outlined"
                  value={editcustomerinformation.name}
                  onChange={handleChange("name")}
                />
              )}
              <TextField
                className={classes.mfield}
                id="contact"
                label="Contact person"
                variant="outlined"
                value={editcustomerinformation.contact}
                onChange={handleChange("contact")}
              />
              <TextField
                className={classes.mfield}
                id="phone"
                label="Phone"
                variant="outlined"
                value={editcustomerinformation.phone}
                onChange={handleChange("phone")}
              />
              <TextField
                className={classes.mfield}
                id="email"
                label="Email address"
                variant="outlined"
                value={editcustomerinformation.email}
                onChange={handleChange("email")}
              />
              <TextField
                className={classes.fullfield}
                id="address"
                label="Billing Address"
                variant="outlined"
                multiline
                value={editcustomerinformation.address}
                onChange={handleChange("address")}
              />
              <TextField
                className={classes.fullfield}
                id="address2"
                label="Delivery Address (If different from above)"
                variant="outlined"
                multiline
                value={editcustomerinformation.address2}
                onChange={handleChange("address2")}
              />
              <TextField
                className={classes.fullfield}
                id="ctrremarks"
                label="Remarks"
                variant="outlined"
                multiline
                value={editcustomerinformation.remarks}
                onChange={handleChange("remarks")}
              />
              <FormControlLabel
                className={classes.fullfield}
                label="Update the Customer Profile"
                control={
                  <Checkbox
                    checked={updatectr}
                    onChange={handleCtrCheck}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleConfirm}
                disabled={
                  editcustomerinformation.type === "Business"
                    ? editcustomerinformation.name &&
                      editcustomerinformation.contact &&
                      editcustomerinformation.phone &&
                      editcustomerinformation.address
                      ? false
                      : true
                    : editcustomerinformation.contact &&
                      editcustomerinformation.phone &&
                      editcustomerinformation.address
                    ? false
                    : true
                }
              >
                CONFIRM
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {section === "orderitems" && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogTitle>
            Order Items
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "#000000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4} sx={{ my: "16px" }}>
              {editorderitems.map((order, index) => (
                <Stack key={index} spacing={4}>
                  {index > 0 && <Divider />}
                  <Stack spacing={2}>
                    <Stack>
                      <Autocomplete
                        className={classes.lfield}
                        id={`item${index}`}
                        //disabled={itemlist ? false : true}
                        open={itemlistopen[index]}
                        onOpen={() => {
                          let tempopenlist = [...itemlistopen]
                          tempopenlist[index]=true
                          setItemlistopen([...tempopenlist])
                        }}
                        onClose={() => { 
                          let tempopenlist = [...itemlistopen]
                          tempopenlist[index]=false
                          setItemlistopen([...tempopenlist])
                          }}
                        value={order.itemid ? {...order} : null}
                        isOptionEqualToValue={(option, value) => option.itemid === value.itemid}
                        getOptionLabel={(option) => option.name || ""}
                        options={itemlist}
                        groupBy={(option) => option.display_group}
                        onChange={itemhandleChange({
                          input: "itemid",
                          index,
                        })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Order item"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {itemlist.length===0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />

                    </Stack>
                    <TextField
                      className={classes.fullfield}
                      id={`desc${index}`}
                      label="Description"
                      placeholder="Description"
                      variant="outlined"
                      //multiline
                      //rows={2}
                      value={order.description}
                      disabled={order.itemid ? false : true}
                      onChange={itemhandleChange({
                        input: "description",
                        index,
                      })}
                    />
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      justifyContent="space-between"
                      spacing={4}
                    >
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="flex-start"
                        spacing={{ xs: 2, sm: 4 }}
                      >
                        <TextField
                          className={classes.sfield}
                          id={`qty${index}`}
                          label="Qty."
                          type="number"
                          value={order.qty}
                          disabled={order.itemid ? false : true}
                          onChange={itemhandleChange({ input: "qty", index })}
                        />
                        <TextField
                          className={classes.sfield}
                          id={`price${index}`}
                          label="Unit Price"
                          type="number"
                          value={order.price}
                          disabled={order.itemid ? false : true}
                          onChange={itemhandleChange({
                            input: "price",
                            index,
                          })}
                        />
                      </Stack>
                      <IconButton
                        aria-label="delete"
                        onClick={deleteitem(index)}
                      >
                        <DeleteIcon fontSize="large" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
              <Stack alignItems="center">
                <IconButton //Add new Item's button
                  color="secondary"
                  aria-label="add"
                  sx={{ width: 40 }}
                  onClick={additem}
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </Stack>
              <Typography variant="body1">
                Total Amount: ${edittotal}
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.diaglogcontainedbtn}
                onClick={handleConfirm}
                disabled={disableitembtn}
              >
                CONFIRM
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {section === "deleteorder" && (
        <Dialog open={dialogswitch[section]} onClose={handleClose}>
          <DialogContent sx={{ px: 4, pt: 6 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
            >
              <Typography
                variant="h2"
                sx={{ fontWeight: "600" }}
              >
                Are you sure to DELETE this order?
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 3, justifyContent: "center" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 2, sm: 4 }}
            >
              <Button
                onClick={handleClose}
                className={classes.diaglogcontainedbtn}
              >
                Cancel
              </Button>
              <Button
                className={classes.diaglogoutlinedbtn}
                onClick={() => setDeldoc(true)}
              >
                DELETE
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
