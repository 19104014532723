import React from 'react';
import ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import LoginProvider from './firebase/LoginProvider';

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <LoginProvider>
          <App />
        </LoginProvider>
      </ThemeProvider>
  </React.StrictMode>,
);
